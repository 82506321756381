import {Button, Modal} from "react-bootstrap";
import React from "react";

const ModalFooter = ({previousStep, nextStep, cancel, resetInput, handleSubmit, disabled}) => {


    return (
        <Modal.Footer>
            <div className='row col-12 flex'>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={previousStep}
                     height="1.4em" viewBox="0 0 448 512" className="arrow-back col">
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                </svg>
                <Button className="col anpassen" onClick={resetInput}>
                    Zurücksetzen
                </Button>
                <Button disabled={disabled} className="col anpassen" type="submit" onClick={handleSubmit}>
                    Bestätigen
                </Button>
                <Button className="col anpassen" onClick={cancel}>
                    Abbrechen
                </Button>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={nextStep}
                     height="1.4em" viewBox="0 0 448 512" className="arrow col">
                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                </svg>
            </div>
        </Modal.Footer>
    );
}

export default ModalFooter;