
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../../styles/ReportPlatform.css";
import "../../styles/Modal.css";

const InfoPopup = () => {

    const [show, setShow] = useState(false);

    function infoSwitch() {
        setShow(!show);
    }

    return (
        <>
            <div className="info" onClick={() => { infoSwitch() }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 192 512" onClick={() => { infoSwitch() }}>
                    <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
                </svg>
            </div>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Meldeplattform
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Worum geht es</h4>
                    <p>
                        Willkommen auf unserer Plattform zur Meldung von Radwegschäden! Hier können Sie Bilder von Radwegschäden hochladen, die automatisch von unserer KI erkannt und klassifiziert werden. Gemeinsam verbessern wir den Zustand der Radwege und erhöhen die Sicherheit für Radfahrerinnen und Radfahrer. Ihre Meldungen sind von großer Bedeutung, um gezielte Maßnahmen zur Behebung der Schäden zu ergreifen.
                        Vielen Dank für Ihre Unterstützung und Ihre Beiträge zur Verbesserung der Radinfrastruktur!
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Schließen</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default InfoPopup;