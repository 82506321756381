import {errorHandling} from "../functions/Errors";
import {
    bikeTraffic,
    bikeTrafficAllStreets
} from "../functions/ApiFunctions";
import {ApiConnect} from "../Dashboard";

export async function getBicycleVolume(city, start, end, roads, addBoxes) {
    start = start.set('hours', 6).set('minutes', 0);
    end = end.set('hours', 22).set('minutes', 0);

    Promise.all([
        ApiConnect.fetchBikeTrafficAllStreets(city, [start, end]),
        ApiConnect.fetchBikeTraffic("month", roads[0], [start, end]),
        ApiConnect.fetchBikeTraffic("dow", roads[0], [start, end]),
        ApiConnect.fetchBikeTraffic("month", roads[1], [start, end]),
        ApiConnect.fetchBikeTraffic("quarter", roads[1], [start, end])
    ])
        .then(([data1, data2, data3, data4, data5]) => {
            const box1 = bikeTrafficAllStreets(data1, city, start, end, "year", "bicycle-volume-all-streets", "bicycle-volume");
            const box2 = bikeTraffic(data2, city, start, end, "month", "bicycle-volume", "bicycle-volume", roads[0]);
            const box3 = bikeTraffic(data3, city, start, end, "dow", "bicycle-volume", "bicycle-volume", roads[0]);
            const box4 = bikeTraffic(data4, city, start, end, "month", "bicycle-volume", "bicycle-volume", roads[1]);
            const box5 = bikeTraffic(data5, city, start, end, "quarter", "bicycle-volume", "bicycle-volume", roads[1]);

            addBoxes([box1, box2, box3, box4, box5])
        }).catch((error) => {
        errorHandling(error);
    });
}