import React, { useState, useEffect } from "react";
import { ResponsiveContainer } from 'recharts';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../styles/Grid.css';
import fullscreen from "../../../images/zoom.png";
import download from "../../../images/download-solid.svg";
import LocationStation from "./Map";
import ApiPicker from "../api/ApiPicker";
import { Dropdown } from "react-bootstrap";
import RoadScore from "./RoadScore";
import RoadScore_Tables from "./RoadScore_Tables";
import Box from "./Box";
import { createBoxData, createGridData, getXYMax } from "../functions/CommonFunctions";
import dayjs from "dayjs";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function Grid({ boxes, updateBoxes, addBoxes, handleShow }) {
    let [x, y] = getXYMax(boxes);

    if (x > 8) {
        x = 0;
        y += 4;
    }

    const layout = {
        lg: boxes.map((box, index) => {
            const grid = box.grid;

            if (grid === 0) {
                const newLayout = { i: index.toString(), w: 4, h: 4, x: x, y: y, minW: 3, minH: 3 };

                if (x < 8) {
                    x += 4;
                } else {
                    x = 0;
                    y += 4;
                }

                return newLayout;
            } else {
                return { i: index.toString(), w: grid.w, h: grid.h, x: grid.x, y: grid.y, minW: 3, minH: 3 };
            }
        })
    }

    const handleLayoutChange = (layout) => {
        let currentX = 0;
        let currentY = 0;
        const newLayout = layout.map((item, index) => {
            const newItem = { ...item };
            if (currentX + newItem.w > 12) {
                // Wenn das Element die aktuelle Zeile überschreitet, bewege es in die nächste Zeile                
                currentX = 0;
                currentY += newItem.h;
            }
            newItem.x = currentX;
            newItem.y = currentY;
            currentX += newItem.w;
            return newItem;
        });

        // Aktualisiere den Zustand der Boxen mit dem neuen Layout
        const newBoxes = boxes.map((box, index) => {
            box.grid = createGridData([
                newLayout[index].w,
                newLayout[index].h,
                newLayout[index].x,
                newLayout[index].y,
            ]);
            return box;
        });
        updateBoxes(newBoxes);
    };


    const handleToggleFullscreen = (boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                box.isFullscreen = !box.isFullscreen;
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const toggleView = (boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                box.currentView = box.currentView === "Kartendaten" ? "Kennzahlen" : "Kartendaten";
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const toggleApiPicker = (boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                box.pickerOpen = !box.pickerOpen;
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const toggleScore = (boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                box.currentView = box.currentView === "Straßenscore" ? "Kennzahlen" : "Straßenscore";
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const toggleRanking = (boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                box.rankingOn = !box.rankingOn;
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const removeBox = (boxIndex) => {
        const newBoxes = boxes.filter((box, index) => index !== boxIndex);
        updateBoxes(newBoxes);
    };

    const addEmptyBox = () => {
        const newBox = createBoxData(["", "", "", "", "", "", "", "", "", "", dayjs('2000-01-01'),
            dayjs('2000-01-01'), "Kennzahlen", false, false, false, []]);
        addBoxes([newBox]);
    };

    //State zum Steuern von .drag
    const [isMobile, setIsMobile] = useState(false);

    //Erkennung von mobilen Geräten
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)'); // Maximalbreite definieren

        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        handleMediaQueryChange(mediaQuery);

        mediaQuery.addListener(handleMediaQueryChange);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    return (
        <div>
            <div className="optionen">
                <div className="option1" onClick={() => addEmptyBox()}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512">
                        <style><svg>fill:white</svg></style>
                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    <div className="hover-text">Box hinzufügen</div>
                </div>
                <div className="option2" onClick={() => handleShow()}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                        <style><svg>fill:white</svg></style>
                        <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                    </svg>
                    <div className="hover-text">Kategorieset wählen</div>
                </div>
            </div>
            <ResponsiveContainer width="100%">
                <ResponsiveReactGridLayout className="test-layout" layouts={layout}
                    onLayoutChange={handleLayoutChange}
                    isDraggable isDroppable isResizable autoSize verticalCompact
                    breakpoints={{ lg: 1220, md: 796, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 12, md: 8, sm: 4, xs: 4, xxs: 4 }} resizeHandles={['e', 'se', 's']}
                    resizableHandle='.react-resizable-handle' draggableHandle=".drag">

                    {/*Boxen erzeugen*/}
                    {boxes.map((box, index) => (
                        <div key={index.toString()} className={`chartBlock col react-grid-item cssTransforms react-resizable react-draggable ${isMobile || box.currentView === "Kartendaten" ? '' : 'drag'}`}
                            style={{ display: 'block' }}>
                            {/*Desktop-Buttonleiste*/}
                            <div className="desktop topButtonBox row">
                                {/*Werte Anpassen bei Kennzahlen*/}
                                {box.currentView !== "Kennzahlen" ? ("") : (
                                    <button className="changeBtn col" onClick={() => toggleApiPicker(index)} onMouseDown={(e) => e.stopPropagation()}>
                                        Werte anpassen
                                    </button>
                                )}
                                {/*Sicht Wechsel zwischen Kartensicht und Kennzahlen*/}
                                <button className="changeBtn col" onClick={() => toggleView(index)} onMouseDown={(e) => e.stopPropagation()}>
                                    {box.currentView === "Kartendaten" ? "Kennzahlen" : "Kartendaten"}
                                </button>
                                {/*Roadrankings*/}
                                {box.currentView !== "Straßenscore" ? ("") : (
                                    <button className="changeBtn col" onClick={() => toggleRanking(index)} onMouseDown={(e) => e.stopPropagation()}>
                                        {box.rankingOn ? "zum Score" : "zum Ranking"}
                                    </button>
                                )}
                                {/*Roadscore an/aus*/}
                                <button className="changeBtn col" onClick={() => toggleScore(index)} onMouseDown={(e) => e.stopPropagation()}>
                                    {box.currentView === "Straßenscore" ? "Score (aus)" : "Score (ein)"}
                                </button>
                                {/*Box entfernen*/}
                                <button className="remove-button col-2" onClick={() => removeBox(index)} onMouseDown={(e) => e.stopPropagation()}>
                                    X
                                </button>
                                {/*Fullscreen*/}
                                {box.currentView !== "Kennzahlen" ? ("") : (
                                    <button className="add-button col-2"
                                        onClick={() => handleToggleFullscreen(index)} onMouseDown={(e) => e.stopPropagation()}>
                                        <img className="fullscreen" src={fullscreen} alt="fullscreen" />
                                    </button>
                                )}
                                {/*Download - ungenutzt*/}
                                {box.currentView !== "Kennzahlen" ? ("") : (
                                    <button className="add-button col" hidden>
                                        <img className="fullscreen" src={download} alt="download" />
                                    </button>
                                )}
                            </div>

                            {/*Mobile Ansicht*/}
                            <Dropdown className="onlyMobile">
                                <Dropdown.Toggle className="topbutton mobilebutton">Anpassen</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {box.currentView !== "Kennzahlen" ? "" :
                                        <Dropdown.Item>
                                            <button className="changeBtn col" onClick={() => toggleApiPicker(index)}>
                                                Werte anpassen
                                            </button>
                                        </Dropdown.Item>}
                                    <Dropdown.Item>
                                        <button className="changeBtn col"
                                            onClick={() => toggleView(index)}>
                                            {box.currentView !== "Kennzahlen" ? 'Kennzahlen' : 'Karten'}
                                        </button>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <button className="changeBtn col" onClick={() => toggleScore(index)}>
                                            {box.currentView === "Straßenscore" ? "Score (aus)" : "Score (ein)"}
                                        </button>
                                    </Dropdown.Item>

                                    {/*Roadrankings*/}
                                    {box.currentView !== "Straßenscore" ? ("") : (
                                        <Dropdown.Item>
                                            <button className="changeBtn col" onClick={() => toggleRanking(index)}>
                                                {box.rankingOn ? "zum Score" : "zum Ranking"}
                                            </button>
                                        </Dropdown.Item>
                                    )}

                                    <Dropdown.Item>
                                        <button className="remove-button col" onClick={() => removeBox(index)}>
                                            X
                                        </button>
                                    </Dropdown.Item>
                                    {box.currentView !== "Kennzahlen" ? "" :
                                        <Dropdown.Item>
                                            <button className="add-button col"
                                                onClick={() => handleToggleFullscreen(index)}>
                                                <img className="fullscreen" src={fullscreen} alt="fullscreen" />
                                            </button>
                                        </Dropdown.Item>}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/*Inhalte*/}

                            {/*Kartensicht*/}
                            {box.currentView === "Kartendaten" && (
                                <div className="boxmap">
                                    <ResponsiveContainer>
                                        <LocationStation />
                                    </ResponsiveContainer>
                                </div>
                            )}

                            {/*Kennzahlen*/}
                            {box.currentView === "Kennzahlen" && (
                                <div className="box noMobileDrag">
                                    <span className="react-resizable-handle" />
                                    {/*Kennzahl-Boxen-Komponenten pro Iteration laden*/}
                                    <Box type={box.type} data={box.data} nameKey={box.nameKey} dataKey={box.dataKey}
                                        title={box.title} city={box.city} timeDimension={box.timeDimension}
                                        start={box.start} end={box.end} boxNr={index} isFullscreen={box.isFullscreen}
                                        toggleFullscreen={() => handleToggleFullscreen(index)} />
                                </div>
                            )}

                            {/*Roadscore laden*/}
                            {box.currentView === "Straßenscore" && box.rankingOn === false && <RoadScore />}

                            {/*Roadtables laden*/}
                            {box.currentView === "Straßenscore" && box.rankingOn === true && <RoadScore_Tables />}

                            <ApiPicker show={box.pickerOpen} handleShow={() => toggleApiPicker(index)} boxes={boxes}
                                updateBoxes={updateBoxes} boxIndex={index} />
                        </div>
                    ))}
                </ResponsiveReactGridLayout>
            </ResponsiveContainer>
        </div>
    );
}