import React from 'react';
import '../../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2>Datenschutzbestimmungen</h2>
      <p>
        Wir legen großen Wert auf den Schutz deiner Daten und möchten dir im Folgenden erläutern, wie wir mit diesen umgehen. Bitte lies dir diese Datenschutzbestimmungen sorgfältig durch, um zu verstehen, wie wir deine Daten erfassen, verwenden, speichern und schützen.
      </p>

      <h3>Erfassung von Daten</h3>
      <p>
        Aktuell erfassen wir keine personenbezogenen Daten, da wir lediglich Bilder sammeln, die von unserer Plattform zur Meldung von Radwegschäden hochgeladen werden. Diese Bilder dienen dem Training und der Weiterentwicklung unseres KI-Modells. Die hochgeladenen Bilder werden anonymisiert und nicht mit persönlichen Informationen verknüpft.
      </p>

      <h3>Verwendung von Daten</h3>
      <p>
        Die von dir hochgeladenen Bilder werden ausschließlich für wissenschaftliche und Forschungszwecke verwendet, um unser KI-Modell zu trainieren und zu verbessern. Die Daten werden nicht für Werbezwecke verwendet und nicht an Dritte weitergegeben, außer im universellen Kontext.
      </p>

      <h3>Speicherung von Daten</h3>
      <p>
        Die hochgeladenen Bilder werden dauerhaft in unserer Datenbank gespeichert. Dadurch können wir sie für das Training und die Verbesserung unseres KI-Modells nutzen. Wir sorgen dafür, dass angemessene Sicherheitsmaßnahmen getroffen werden, um deine Daten vor unbefugtem Zugriff und Missbrauch zu schützen.
      </p>

      <h3>Datensicherheit</h3>
      <p>
        Wir setzen technische und organisatorische Maßnahmen ein, um die Sicherheit deiner Daten zu gewährleisten. Wir ergreifen angemessene Vorkehrungen, um sicherzustellen, dass deine Daten sicher und gemäß geltenden Datenschutzgesetzen behandelt werden.
      </p>

      <h3>Rechte der Nutzer</h3>
      <p>
        Gemäß den geltenden Datenschutzgesetzen hast du das Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung deiner Daten. Da wir jedoch keine personenbezogenen Daten erfassen, gibt es in Bezug auf deine Daten keine individuellen Rechte. Falls du Fragen dazu hast, kannst du dich gerne mit uns in Verbindung setzen.
      </p>

      <p className="update-date">Bitte beachte, dass diese Datenschutzbestimmungen jederzeit aktualisiert werden können. Überprüfe regelmäßig unsere Website, um sicherzustellen, dass du über die aktuellen Bestimmungen informiert bist.</p>

      <p className="update-date">Stand: 08.06.2023</p>

      <p>
        Vielen Dank für dein Verständnis und deine Unterstützung bei der Verbesserung der Radinfrastruktur.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
