export function getRoadConditionsTranslations() {
    return {
        wet: "Nass",
        dry: "Trocken",
        icy: "Vereist",
        slick: "Glatt",
        null: "keine Angabe"
    };
}

export function getWeatherConditionsTranslations() {
    return {
        "Partially cloudy": "Leicht bewölkt",
        Overcast: "Wolkig",
        Snow: "Schnee",
        Clear: "Klarer Himmel",
        Rain: "Regen"
    };
}

export function getCities() {
    return {
        "oldenburg": "Oldenburg",
        "osnabrueck": "Osnabrück"
    };
}

export function getCitiesShort() {
    return {
        "oldenburg": "OL",
        "osnabrueck": "OS"
    };
}

export function getOverallTopics(all) {
    if (all) {
        return {
            "standard": "Standard",
            "bicycle-volume": "Fahrrad-Verkehrsaufkommen",
            "concerns": "Bürger Meldungen",
            "accidents": "Unfälle"
        };
    } else {
        return {
            "bicycle-volume": "Fahrrad-Verkehrsaufkommen",
            "concerns": "Bürger Meldungen",
            "accidents": "Unfälle"
        };
    }
}

export function getTopics() {
    return {
        "bicycle-volume-all-streets": "Fahrrad-Verkehrsaufkommen Zählstellenvergleich",
        "bicycle-volume": "Fahrrad-Verkehrsaufkommen Zählstellen",
        "concerns-duration": "Dauer bis Meldungen behoben",
        "concerns-time": "Meldungen nach Zeit",
        "concerns-type": "Meldungen nach Typ",
        "concerns-type-problem": "Meldungen nach erkanntem Problem",
        "accidents-type": "Unfälle nach Unfalltyp",
        "accidents-weather": "Unfälle nach Witterung",
        "accidents-temperature": "Unfälle nach Temperatur",
        "accidents-time": "Unfälle nach Zeit",
        "accidents-perpetrator": "Unfälle nach Verursachern",
        "accidents-people-time": "Unfallbeteiligte nach Zeit",
        "accidents-people-type": "Unfallbeteiligte nach Unfalltyp",
        "accidents-road-condition": "Unfälle nach Oberfläche",
        "accidents-monetary-damage": "Verursacher nach Schadenshöhe",
        "avg-accidents-monetary-damage": "Durchschnittlicher Schaden in € pro Verursacher",
        "accidents-monetary-damage-people": "Verursacher nach Schadenshöhe in € (Personen/Sach)",
        "avg-accidents-monetary-damage-people": "Durchschnittlicher Schaden in € pro Verursacher (Personen/Sach)"
    };
}

export function getTimeDimensions() {
    return {
        "year": "Jahr",
        "month": "Monat",
        "dow": "Tage in der Woche",
        "day": "Tage im Monat",
        "hour": "Stunde",
        "quarter": "15 Minuten"
    };
}

export function getTimeSpans() {
    return {
        "lastWeek": "Letzte Woche",
        "last14Days": "Letzte 14 Tage",
        "last30Days": "Letzte 30 Tage",
        "lastMonth": "Letzter Monat",
        "lastQuarter": "Letztes Quartal",
        "lastHalfYear": "Letztes Halbjahr",
        "lastYear": "Letztes Jahr",
        "last3Years": "Letzte 3 Jahre",
        "last5Years": "Letzte 5 Jahre",
    };
}

export function getDayTimes() {
    return {
        "day": "Tagsüber (6-22 Uhr)",
        "night": "Nachts (22-6 Uhr)",
        "morning": "Morgens (6-9 Uhr)",
        "afternoon": "Nachmittags (15-19 Uhr)"
    };
}

export function getCharts() {
    return {
        "pie": "Tortendiagramm",
        "line": "Liniendiagramm",
        "bar": "Balkendiagramm",
        "area": "Flächendiagramm",
        "scatter": "Punktediagramm",
        "radial": "Radialdarstellung",
        "bar-stacked": "Gestapeltes Balkendiagramm",
        "area-stacked": "Flächendiagramm"
    };
}

export function getMonthShortcuts() {
    return {
        1: 'Jan', // Januar
        2: 'Feb', // Februar
        3: 'Mär', // März
        4: 'Apr', // April
        5: 'Mai', // Mai
        6: 'Jun', // Juni
        7: 'Jul', // Juli
        8: 'Aug', // August
        9: 'Sep', // September
        10: 'Okt', // Oktober
        11: 'Nov', // November
        12: 'Dez', // Dezember
    };
}

export function getNodeStateProperties()  {
    return {
        "V1": "v_score_v1",
        "V2": "v_score_v2",
        "Anomaly": "anomaly",
        "TimeLoss": "time_loss"
    };
}

export function getRoadStateProperties()  {
    return {
        "V1Seg": "v_score_v1",
        "V2Seg": "v_score_v2",
        "AnomalieSeg": "anomaly",
        "TimeLossSeg": "time_loss"
    };
}

export function getScoreStateProperties()  {
    return {
        "Score": "full_percentile_score",
        "Unfälle": "full_percentile_accidents_per_m",
        "Meldungen": "full_percentile_concerns_per_m",
        "Verkehrsaufkommen": "full_percentile_daily_traffic",
        "Zeitverlust": "full_percentile_time_loss",
        "Anomalie": "full_percentile_anomaly",
        "Erschütterungen": "full_percentile_v_score_v2"
    };
}

export function getHeatmapProperties() {
    return {
        "AnomalieV1": "anomaly_v1",
        "AnomalieV2": "anomaly_v2",
        "FrequencyRoad": "frequency",
        "V1Road": "v_score_v1",
        "V2Road": "v_score_v2",
        "WaitingTimeRoad": "waiting_time",
        "NormSpeedRoad": "normalized_speed",
        "AbsoluteSpeedRoad": "absolute_speed",
        "FullBrakings": "full_brakings"
    };
}

export function getValue(road, state) {
    switch (state) {
        case "AnomalieV1":
            return road.anomaly_v1;
        case "AnomalieV2":
            return road.anomaly_v2;
        case "FrequencyRoad":
            return road.frequency;
        case "V1Road":
            return road.v_score_v1;
        case "V2Road":
            return road.v_score_v2;
        case "WaitingTimeRoad":
            return road.waiting_time;
        case "NormSpeedRoad":
            return road.normalized_speed;
        case "AbsoluteSpeedRoad":
            return road.absolute_speed;
        case "FullBrakings":
            return road.full_brakings;
        default:
            return null;
    }
}