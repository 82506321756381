import React, { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function Scatter_Chart(props){

    return (
      <ResponsiveContainer width="100%" height="88%">
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis  dataKey={props.dataX} />
          <YAxis dataKey={props.dataY} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter data={props.data} fill={props.colors[0]} />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
export default Scatter_Chart;