import React, {useState} from 'react';
import Grid from './canvas/Grid'
import {Modal, Button, ModalBody, ModalFooter} from "react-bootstrap";
import ApiConnection from "./api/ApiConnection";
import {getFastload} from "./templates/FastLoad";
import CanvasInput from "./api/CanvasInput";

export const ApiConnect = new ApiConnection();

function Dashboard() {
    const [boxes, setBoxes] = useState([]);

    const addBoxes = (newEntries) => {
        const newBoxes = boxes.concat(newEntries);
        setBoxes(newBoxes);
    }

    const updateBoxes = (newBoxes) => {
        setBoxes(newBoxes);
    }

    const [open, setOpen] = useState(true);
    const onCloseModal = () => setOpen(false);

    const fastload = () => { getFastload(addBoxes).then(() => { onCloseModal(); }); }

    const [show, setShow] = useState(false);
    const handleShow = () => { setShow(!show); }

    return (
        <div className='street container-fluid'>
            <Modal show={open} onHide={onCloseModal} centered fullscreen>
                <Modal.Header closeButton closeVariant="close">
                    <Modal.Title id="contained-modal-title-lg">Einstieg zum Dashboard</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <div className='flex intro'>
                        <Button className="col btn intro" onClick={fastload}>
                            <h2>Schnelleinstieg</h2>
                        </Button>
                        <Button className="col btn intro" onClick={() => { onCloseModal(); handleShow(); }}>
                            <h2>Kategorieset auswählen</h2>
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter className='einstiegfooter'>
                    <div className='flex row descintro'>
                        <p>Wähle Schnelleinstieg um das Standardset an Kennzahlen zu laden, Kategorieset auswählen um
                            selber ein vordefiniertes Set zu wählen oder drücke x um alles selber aufzubauen.
                        </p>
                    </div>
                </ModalFooter>
            </Modal>

            <Grid boxes={boxes} updateBoxes={updateBoxes} addBoxes={addBoxes} handleShow={handleShow}/>

            <CanvasInput show={show} handleShow={handleShow} addBoxes={addBoxes}/>
        </div>
    );
}

export default Dashboard;