import {errorHandling} from "../functions/Errors";
import {
    accidentGround, accidentTemperature,
    accidentTypes, accidentWeather,
    bikeTrafficAllStreets,
    concernsType,
    concernsTypeProblem
} from "../functions/ApiFunctions";
import {ApiConnect} from "../Dashboard";

export async function getFastload(addBoxes) {
    const ol = "oldenburg";
    const os = "osnabrueck";

    const start = "2021-01-01 00:00:00";
    const end = "2022-01-01 00:00:00";

    Promise.all([
        ApiConnect.fetchBikeTrafficAllStreets(ol, [start, end]),
        ApiConnect.fetchConcernsType(ol, [start, end]),
        ApiConnect.fetchAccidentTypes(ol, [start, end]),
        ApiConnect.fetchConcernsTypeProblem(ol, [start, end]),
        ApiConnect.fetchConcernsType(os, [start, end]),
        ApiConnect.fetchAccidentTypes(os, [start, end]),
        ApiConnect.fetchAccidentGround(os, [start, end]),
        ApiConnect.fetchAccidentWeather(ol, [start, end]),
        ApiConnect.fetchAccidentTempLowerMinus10(ol, [start, end]),
        ApiConnect.fetchAccidentTempMinus10to0(ol, [start, end]),
        ApiConnect.fetchAccidentTemp0to10(ol, [start, end]),
        ApiConnect.fetchAccidentTemp10to20(ol, [start, end]),
        ApiConnect.fetchAccidentTemp20to30(ol, [start, end]),
        ApiConnect.fetchAccidentTempHigher30(ol, [start, end])
    ])
        .then(([data1, data2, data3, data4, data5, data6, data7, data8,
                   dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30]) => {
            const box1 = bikeTrafficAllStreets(data1, ol, start, end, "year", "bicycle-volume-all-streets", "bicycle-volume");
            const box2 = concernsType(data2, ol, start, end, "year", "concerns-type", "concerns");
            const box3 = accidentTypes(data3, ol, start, end, "year", "accidents-type", "accidents");

            const box4 = concernsTypeProblem(data4, ol, start, end, "year", "concerns-type-problem", "concerns");
            const box5 = concernsType(data5, os, start, end, "year", "concerns-type", "concerns");
            const box6 = accidentTypes(data6, os, start, end, "year", "accidents-type", "accidents");

            const box7 = accidentGround(data7, os, start, end, "year", "accidents-road-condition", "accidents");
            const box8 = accidentWeather(data8, ol, start, end, "year", "accidents-weather", "accidents");
            const box9 = accidentTemperature(dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30, ol,
                start, end, "year", "accidents-temperature", "accidents");

            addBoxes([box1, box2, box3, box4, box5, box6, box7, box8, box9])
        }).catch((error) => {
        errorHandling(error);
    });
}