import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import {sortByKeyAsc} from "../functions/CommonFunctions";

const RADIAN = Math.PI / 180;

const renderOutside = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent >=0.05) {
    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }
};

let RenderCustomizedLabelLine = function (props) {
  return (props.percent >= 0.05 ? <path stroke={props.stroke} d={`M${props.points[0].x},${props.points[0].y}L${props.points[1].x},${props.points[1].y}`}
                                        className="customized-label-line"/> : <polyline stroke={props.stroke} fill="none"/>)
}

function Pie_Chart({data, dataX, dataY, colors, type}) {
  data = Array.from(data);
  data = sortByKeyAsc(data, dataY);

  return (
    <ResponsiveContainer width='99%' height='88%'>    
        <PieChart>
          <Legend verticalAlign="bottom" align="center" background-color={"red"}
                  formatter={(value) => <span style={{fontSize: '15px'}} className="text-color-class">{value}</span>}/>
          <Pie data={data} cx="50%" cy="50%" startAngle={90} endAngle={-270} labelLine={RenderCustomizedLabelLine}
               label={renderOutside} outerRadius="60%" fill="#8884d8" nameKey={dataX} dataKey={dataY}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={type === "pie" ? colors[index % colors.length] : "#" + entry.euska_accident_color_simplified}/>
            ))}
          </Pie>
          <Tooltip/>
        </PieChart>  
    </ResponsiveContainer>    
  );
}

export default Pie_Chart;