import React from "react";
import {Accordion} from "react-bootstrap";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {filterMoneyDamage} from "../../functions/FilterFunctions";

function AccordionDamageAmount({moneyDamageFrom, moneyDamageTo, filterCondition, filterFunction, updateMoneyDamageFrom,
                                   updateMoneyDamageTo}) {

    return (
        <Accordion>
            <Accordion.Item eventKey="Schadenhöhe">
                <Accordion.Header>Schadenshöhe in €</Accordion.Header>
                <Accordion.Body>
                    <div className="row">
                        <button className={`col-5 accFilterBtn ${filterCondition.includes("money") ? "selected" : ""}`}
                            onClick={() => filterFunction("money")}>
                            <span className="icon-filter-container">
                              <img src={filterCondition.includes("money") ? checked : unchecked}
                                   alt={filterCondition.includes("money") ? "checked Icon" : "unchecked Icon"}
                                   className="icon-filter"/>
                                Individuell:
                            </span>
                        </button>
                        <input type="number" className="col-3 moneyInput" placeholder="von" value={moneyDamageFrom}
                               onChange={(e) => updateMoneyDamageFrom(e.target.value)}/>
                        <span className="col-1 icon-filter-container">{" bis "}</span>
                        <input type="number" placeholder="bis" className="col-3 moneyInput" value={moneyDamageTo}
                               onChange={(e) => updateMoneyDamageTo(e.target.value)}/>
                    </div>
                    {filterMoneyDamage.map((filter, index) => (
                        <button key={index}
                                className={`col-12 accFilterBtn ${moneyDamageFrom === filter.valueFrom && moneyDamageTo === filter.valueTo ? "selected" : ""}`}
                                onClick={() => {
                                    filterFunction("money");
                                    updateMoneyDamageFrom(filter.valueFrom);
                                    updateMoneyDamageTo(filter.valueTo);
                                }}>
                            <span className="icon-filter-container">
                              <img src={moneyDamageFrom === filter.valueFrom && moneyDamageTo === filter.valueTo ? checked : unchecked}
                                   alt={moneyDamageFrom === filter.valueFrom && moneyDamageTo === filter.valueTo ? "checked Icon" : "unchecked Icon"}
                                   className="icon-filter"/>
                                {filter.label}
                            </span>
                        </button>
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionDamageAmount;