import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const StackedArea_chart = ({ data, dataX, dataY, colors }) => {
  const filteredColors = colors.filter((color, index) => {
    const dataKey = dataY[index];
    return data.some((item) => item[dataKey] > 0);
  });

  return (
    <ResponsiveContainer width="98%" height="88%">
      <AreaChart width={500} height={400} data={data} margin={{top: 10, right: 30, left: 0, bottom: 5}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey={dataX}/>
        <YAxis/>
        <Tooltip/>
        <Legend/>
        {filteredColors.map((color, index) => (
          <Area key={index} type="monotone" dataKey={dataY[index]} stackId={index} stroke={color} fill={color}/>
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StackedArea_chart;