import {errorHandling} from "../functions/Errors";
import {
    accidentTypes,
    bikeTrafficAllStreets,
    concernsType,
    concernsTypeProblem
} from "../functions/ApiFunctions";
import {ApiConnect} from "../Dashboard";

export async function getStandard(city, start, end, addBoxes) {
    Promise.all([
        ApiConnect.fetchBikeTrafficAllStreets(city, [start, end]),
        ApiConnect.fetchConcernsType(city, [start, end]),
        ApiConnect.fetchAccidentTypes(city, [start, end]),
        ApiConnect.fetchConcernsTypeProblem(city, [start, end])
    ])
        .then(([data1, data2, data3, data4]) => {
            const box1 = bikeTrafficAllStreets(data1, city, start, end, "year", "bicycle-volume-all-streets", "bicycle-volume");
            const box2 = concernsType(data2, city, start, end, "year", "concerns-type", "concerns");
            const box3 = accidentTypes(data3, city, start, end, "year", "accidents-type", "accidents");
            const box4 = concernsTypeProblem(data4, city, start, end, "year", "concerns-type-problem", "concerns");

            addBoxes([box1, box2, box3, box4])
        }).catch((error) => {
        errorHandling(error);
    });
}