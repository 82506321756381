import L from "leaflet";
import bike from "../../../images/bicycle-solid.svg";
import bus from "../../../images/bus-simple-solid.svg";
import accidents from "../../../images/triangle-exclamation-solid.svg";
import biker from "../../../images/person-biking-solid.svg";
import bug from "../../../images/bug-solid.svg";
import eBike from "../../../images/motorcycle-solid.svg";
import car from "../../../images/car-solid.svg";
import carAccident from "../../../images/car-burst-solid.svg";
import dog from "../../../images/dog-solid.svg";
import truck from "../../../images/truck-solid.svg";
import people from "../../../images/person-walking-solid.svg";
import report from "../../../images/message-regular.svg";
import rdd from "../../../images/message-regular rdd.svg";
import question from "../../../images/question-solid.svg";
import station from "../../../images/traffic-sign-klein.svg";
import child from "../../../images/child-solid.svg";
import tree from "../../../images/tree-solid.svg";
import bikeM from "../../../images/bicycle-solid-meldung.svg";
import carM from "../../../images/car-solid-meldung.svg";
import broom from "../../../images/broom-solid.svg";
import bulb from "../../../images/lightbulb-solid.svg";
import barrier from "../../../images/road-barrier-solid.svg";
import pray from "../../../images/hands-praying-solid.svg";
import trafficLight from "../../../images/traffic-light-solid.svg";
import trash from "../../../images/trash-can-solid.svg";
import trafficSign from "../../../images/triangle-exclamation-solid.svg";
import water from "../../../images/water-solid.svg";

export const busIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${bus}" alt="Bus Icon" />`,
    iconSize: [25, 25],
});

export const accidentsIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${accidents}" alt="Unfall Icon" />`,
    iconSize: [25, 25],
});

export const bikeIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${bike}" alt="Bike Icon" />`,
    iconSize: [25, 25],
});

export const bikerIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${biker}" alt="Biker Icon" />`,
    iconSize: [25, 25],
});

export const bugIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${bug}" alt="Bug Icon" />`,
    iconSize: [25, 25],
});

export const eBikeIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${eBike}" alt="E-Bike Icon" />`,
    iconSize: [25, 25],
});

export const carIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${car}" alt="Auto Icon" />`,
    iconSize: [25, 25],
});

export const carAccidentIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${carAccident}" alt="Auto-Accident Icon" />`,
    iconSize: [25, 25],
});

export const dogIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${dog}" alt="Dog Icon" />`,
    iconSize: [25, 25],
});

export const truckIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${truck}" alt="Truck Icon" />`,
    iconSize: [25, 25],
});

export const peopleIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${people}" alt="People Icon" />`,
    iconSize: [25, 25],
});

export const reportIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${report}" alt="Meldung Icon" />`,
    iconSize: [25, 25],
});

export const rddIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${rdd}" alt="RDD Icon" />`,
    iconSize: [25, 25],
});

export const questionIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${question}" alt="Sonstige Icon" />`,
    iconSize: [25, 25],
});

export const stationIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${station}" alt="Haltestelle Icon" />`,
    iconSize: [25, 25],
});

export const childIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${child}" alt="Child Icon" />`,
    iconSize: [25, 25],
});

export const treeIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${tree}" alt="Tree Icon" />`,
    iconSize: [25, 25],
});

export const bikeMIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${bikeM}" alt="Bike Icon" />`,
    iconSize: [25, 25],
});

export const carMIcon = L.divIcon({
    className: "custom-icon",
    html: `<img src="${carM}" alt="Auto Icon" />`,
    iconSize: [25, 25],
});

export const broomIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${broom}" alt="Broom Icon" />`,
    iconSize: [25, 25],
});

export const bulbIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${bulb}" alt="Bulb Icon" />`,
    iconSize: [25, 25],
});

export const barrierIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${barrier}" alt="Barrier Icon" />`,
    iconSize: [25, 25],
});

export const prayIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${pray}" alt="Pray Icon" />`,
    iconSize: [25, 25],
});

export const trafficLightIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${trafficLight}" alt="Traffic Light Icon" />`,
    iconSize: [25, 25],
});

export const trashIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${trash}" alt="Trash Icon" />`,
    iconSize: [25, 25],
});

export const trafficSignIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${trafficSign}" alt="Traffic Sign Icon" />`,
    iconSize: [25, 25],
});

export const waterIcon = L.divIcon({
    className: 'custom-icon',
    html: `<img src="${water}" alt="Wasser Icon" />`,
    iconSize: [25, 25],
});

export const getMarkerIcon = (data, fillColor) => {
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
        <path fill="${fillColor}" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
      </svg>
    `;

    return L.divIcon({
        className: "custom-icon",
        html: svgString,
        iconSize: [40, 40],
    });
};

export const whiteIcon = L.divIcon({
    className: "white-marker-icon",
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 41"><circle cx="12.5" cy="20.5" r="10" fill="transparent" /></svg>`,
    iconSize: [35, 35],
});