import React, { useState, useCallback } from 'react';
import { Dropdown, DropdownButton, Button } from 'react-bootstrap';
import "../../styles/bundeslaender.css";

const BundeslaenderDropdown = ({ onSelect, activeStates }) => {
  const [selectedStates, setSelectedStates] = useState(activeStates || []);

  const handleSelect = useCallback((bundesland) => {
    setSelectedStates(prevSelectedStates => {
      const index = prevSelectedStates.indexOf(bundesland);
      if (index === -1) {
        return [...prevSelectedStates, bundesland];
      } else {
        const updatedSelection = [...prevSelectedStates];
        updatedSelection.splice(index, 1);
        return updatedSelection;
      }
    });
  }, []);

  const handleLoadButtonClick = () => {
    onSelect(selectedStates);
  };

  const bundeslaender = [
    'Deutschland',
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen',
    'Nachbarländer'
  ];

  return (
    <div id="bundeslaender-dropdown" className="dropdown-container">
      <DropdownButton id="bundeslaender-dropdown" title="Übersicht wählen">
      <div className="scrollable-dropdown">
        {bundeslaender.map((bundesland, index) => (
          <Dropdown.Item
            key={index}
            eventKey={bundesland}
            onClick={() => handleSelect(bundesland)}
            style={{
              width: '100%',
              color: selectedStates.includes(bundesland) ? 'white' : 'black',
              background: selectedStates.includes(bundesland) ? '#6c9c34' : 'white',
              border: '.2px dotted white'
            }}
          >
            {bundesland}
          </Dropdown.Item>
        ))}
         </div>
      </DropdownButton>
      <Button className='laden' onClick={handleLoadButtonClick}>Laden</Button>
    </div>
  );
};

export default BundeslaenderDropdown;