export function sortByKey(array, key) {
    return array.sort(function (a, b) {
        const x = a[key];
        const y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export function sortByKeyAsc(array, key) {
    return array.sort(function (a, b) {
        const x = a[key];
        const y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

export function createBoxData(data) {
    return {
        type: data[0],
        data: data[1],
        nameKey: data[2],
        dataKey: data[3],
        title: data[4],
        city: data[5],
        overallTopic: data[6],
        topic: data[7],
        road: data[8],
        timeDimension: data[9],
        start: data[10],
        end: data[11],
        currentView: data[12],
        rankingOn: data[13],
        isFullscreen: data[14],
        pickerOpen: data[15],
        grid: createGridData(data[16])
    };
}

export function createGridData(data) {
    if (data.length !== 4) {
        return 0;
    }

    return {
        w: data[0],
        h: data[1],
        x: data[2],
        y: data[3]
    };
}

export function getXYMax(boxes) {
    const filteredBoxes = boxes.filter(box => box.grid !== 0);

    if (!filteredBoxes.length) {
        return [0, 0];
    } else {
        const lastBox = filteredBoxes.reduce((prev, current) => (prev && prev.grid.y >= current.grid.y) ?
            (prev && prev.grid.x > current.grid.x) ? prev : current : current);

        if (lastBox.grid.x + lastBox.grid.w > 8) {
            return [0, lastBox.grid.y + lastBox.grid.h];
        } else {
            return [lastBox.grid.x + lastBox.grid.w, lastBox.grid.y];
        }
    }
}