import React from 'react';
import '../../../styles/Scale.css';

function GradientScale() {
    return (
        <div className="scale-gradient">
            <div className="gradient-box"/>
        </div>
    );
}

export default GradientScale;