import React from "react";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";

function MyTimePicker({date, updateDate, label}) {
    const accept = (date) => {
        if (date !== null) {
            updateDate(date);
        }
    }

    return (
        <DemoContainer sx={{"&": {pt: "20px", pb: "10px", width: "300px", display: "flex"}}} components={['TimePicker']}>
            <TimePicker value={date} label={label}
                        onChange={(value) => accept(value)}
                        onAccept={(value) => accept(value)}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        sx={{
                            ".MuiInputBase-input": {
                                "&": {
                                    borderWidth: "0px",
                                    backgroundColor: "transparent",
                                    color: "#FFF",
                                    pt: "25px",
                                    pb: "5px"
                                },
                            },

                            ".MuiStack-root": {
                                "&": {
                                    borderWidth: "0px",
                                    backgroundColor: "transparent",
                                    color: "#FFF"
                                },
                                paddingTop: 0
                            },

                            ".MuiSvgIcon-root": {
                                "&": {
                                    color: "#FFF"
                                },
                            },

                            '.MuiIconButton-root': {
                                '&': {
                                    width: "40px",
                                    margin: "5px"
                                },
                                '&:hover': {
                                    backgroundColor: "#656565"
                                },
                            },

                            '.MuiFormLabel-root': {
                                '&': {
                                    color: "#FFF",
                                    position: "absolute",
                                    top: "10px",
                                    zIndex: 1
                                },
                                '.Mui-selected': {
                                    color: "#FFF",
                                    position: "absolute",
                                    top: "10px",
                                    zIndex: 1
                                },
                            },

                            ".MuiOutlinedInput-root": {
                                "&": {
                                    backgroundColor: "#6c9c34",
                                    borderColor: "#6c9c34",
                                    borderWidth: "1px",
                                    borderRadius: "0.5rem"
                                },
                                "& fieldset": {
                                    borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                    borderColor: "transparent",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "transparent",
                                }
                            }
                        }}
                        slotProps={{
                            layout: {
                                sx: {
                                    '& .MuiClockPointer-root, .MuiClock-pin': {
                                        '&': {
                                            backgroundColor: '#6c9c34',
                                        },
                                    },
                                    '.MuiIconButton-root': {
                                        '&': {
                                            width: "30px",
                                        },
                                        '&:hover': {
                                            backgroundColor: "#656565",
                                        },
                                    },
                                    '.MuiButton-root': {
                                        '&': {
                                            backgroundColor: '#FFF',
                                            color: '#6c9c34',
                                            width: "10px",
                                        },
                                        '&:hover': {
                                            backgroundColor: '#6c9c34',
                                            color: '#FFF',
                                        },
                                    },
                                }
                            },
                            popper: {
                                style: {
                                    zIndex: 2000
                                }
                            },
                        }}/>
        </DemoContainer>
    );
}

export default MyTimePicker;