import React from "react";
import {Accordion} from "react-bootstrap";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {filterOptions} from "../../functions/FilterFunctions";

function AccordionGeneral({filterCondition, injured, filterFunction, updateInjured}) {

    return (
        <Accordion>
            <Accordion.Item eventKey="Allgemein">
                <Accordion.Header>Allgemein</Accordion.Header>
                <Accordion.Body>
                    {filterOptions.map((option, index) => (
                        <div className="row">
                            <button key={index}
                                    className={`col-12 accFilterBtn ${filterCondition.includes(option.value) ? "selected" : ""}`}
                                    onClick={() => filterFunction(option.value)}>
                            <span className="icon-filter-container">
                              <img src={filterCondition.includes(option.value) ? checked : unchecked}
                                   alt={filterCondition.includes(option.value) ? "checked Icon" : "unchecked Icon"}
                                   className="icon-filter"/>
                                {option.label}
                            </span>
                            </button>
                            {option.value === "verletzte" &&
                                <input type="number" className="col-3 moneyInput" value={injured}
                                       onChange={(e) => updateInjured(e.target.value)}/>}
                        </div>
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionGeneral;