export function getBusLineColors() {
    const busColors = {};

    //Oldenburger Linien
    busColors["280"] = "#0090c3";
    busColors["301"] = "#bbb6d4";
    busColors["302"] = "#ff2e17";
    busColors["304"] = "#fd93c5";
    busColors["306"] = "#ffd639";
    busColors["308"] = "#35ccf6";
    busColors["309"] = "#fb3e9f";
    busColors["310"] = "#c52b1e";
    busColors["311"] = "#7a441c";
    busColors["313"] = "#38647e";
    busColors["314"] = "#b0d243";
    busColors["315"] = "#0060a0";
    busColors["318"] = "#4b9445";
    busColors["320"] = "#7c7f32";
    busColors["321"] = "#ffbc87";
    busColors["322"] = "#00b2b4";
    busColors["323"] = "#47d0f6";
    busColors["324"] = "#ff9027";
    busColors["325"] = "#797ca2";
    busColors["329"] = "#42155b";
    busColors["330"] = "#44b94a";
    busColors["340"] = "#6e4391";
    busColors["350"] = "#116081";

    //Osnabrücker Linien
    busColors["10"] = "#945ea4";
    busColors["12"] = "#2e2382";
    busColors["13"] = "#009ee3";
    busColors["14"] = "#ec6500";
    busColors["15"] = "#f7a500";
    busColors["16"] = "#007a3b";
    busColors["17"] = "#00a75c";
    busColors["17 (zeitweise Bedienung)"] = "#00a75c";
    busColors["18"] = "#1082c7";
    busColors["19"] = "#004e9e";
    busColors["20"] = "#945ea4";
    busColors["151"] = "#f7a500";
    busColors["152"] = "#f7a500";
    busColors["160"] = "#9c9c9d";
    busColors["381"] = "#009fe2";
    busColors["392"] = "#004e9e";
    busColors["454"] = "#d51518";
    busColors["462"] = "#9c9b9a";
    busColors["491"] = "#9c9c9d";
    busColors["533"] = "#312783";
    busColors["581"] = "#009fe2";
    busColors["584"] = "#d61418";
    busColors["M1"] = "#01924e";
    busColors["M2"] = "#2e2382";
    busColors["M3"] = "#94c11a";
    busColors["M4"] = "#d40a11";
    busColors["M5"] = "#e24f97";
    busColors["X52"] = "#9c9c9d";
    busColors["X273"] = "#d51518";
    busColors["X460"] = "#009fe2";
    busColors["X492"] = "#01924f";
    busColors["X610"] = "#004f9e";
    busColors["R15"] = "#f7a703";
    busColors["R16"] = "#007b3d";
    busColors["S10"] = "hsl(88, 50%, 41%)";
    busColors["Anrufbus 113"] = "hsl(28, 80%, 52%)";

    return busColors;
}

export function getColorClass(score) {

    if (score >= 0 && score <= 0.3) {
        return "#2ecc71"; // Green (0% - 30%)
    } else if (score > 0.3 && score <= 0.5) {
        return "#fff"; // Light Grey (31% - 50%)
    } else if (score > 0.5 && score <= 0.7) {
        return "#ffff00"; // Yellow (51% - 70%)
    } else if (score > 0.7 && score <= 0.85) {
        return "#ffa500"; // Orange (71% - 85%)
    } else if (score > 0.85 && score < 0.92) {
        return "#ff6347"; // Red Orange (86% - 91%)
    } else if (score > 0.92) {
        return "#ff0000"; // Red (92% - 100%)
    }
    else { return "transparent" }
}

export function getSlopeColorClass(slope) {

    if (slope >= 0 && slope < 1) {
        return "#008B00";
    } else if (slope >= 1 && slope < 2) {
        return "#00EE00";
    } else if (slope >= 2 && slope < 4) {
        return "#FFFF00";
    } else if (slope >= 4 && slope < 6) {
        return "#FFA500";
    } else if (slope >= 6 && slope < 8) {
        return "#FF0000";
    } else if (slope >= 8) {
        return "#8B0000";
    }
    else { return "#D3D3D3" }
}

export function getPathTypeColorClass(pathType) {

    switch(pathType) {
        case "Busspur":
            return "#FFFF00";
        case "Fahrradstraße":
            return "#008B00";
        case "Geh-/Radweg":
            return "#FFA500";
        case "Radfahrspur":
            return "#0000FF";
        case "Radweg":
            return "#FF0000";
        default:
            return "#D3D3D3";
    }
}