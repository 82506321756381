import React from 'react';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='customTooltip'>
        <p >{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {entry.name === 'avg_cognos_monetary_damage'
              ? `${parseFloat(entry.value).toFixed(2)} €`
              : `${(entry.value).toFixed(2)} €`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
