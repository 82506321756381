// Importe der benötigten Module
import React, { useEffect, useState } from "react";
import { Tab, Nav, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import ApiConnection from "../api/ApiConnection";
import Gut from '../../../images/angles-up-solid.svg';
import Mittel from '../../../images/angles-right-solid.svg';
import Schlecht from '../../../images/angles-down-solid.svg';
import checked from "../../../images/square-check-regular.svg";
import unchecked from "../../../images/square-regular.svg";

// RoadScore_Tables Komponente
const RoadScore_Tables = () => {
  const ApiConnect = new ApiConnection(); // Eine Instanz der ApiConnection-Klasse erstellen

  // State-Variablen
  const [roadNames, setRoadNames] = useState({});
  const [tableScore, setTableScore] = useState("");
  const [scoreTown, setScoreTown] = useState("Oldenburg");
  const [scoreState, setScoreState] = useState("Score");
  const [open, setOpen] = useState(false);

  // Öffnen und Schließen des Modal
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  // Übersetzungen für Straßenscore-Kategorien
  const subjectTranslations = {
    "full_percentile_score": "Straßenscore",
    "full_percentile_accidents_per_m": "Unfälle per m",
    "full_percentile_concerns_per_m": "Meldungen per m",
    "full_percentile_quality_total": "Qualität",
    "full_percentile_damages_per_m": "Schäden",
    "full_percentile_v_score_v2": "Erschütterung",
    "full_percentile_anomaly": "Anomalie",
    "full_percentile_time_loss": "Zeitverlust",
    "full_percentile_daily_traffic": "Verkehrsaufkommen"
  };


  // useEffect für die API-Anfrage
  useEffect(() => {
    ApiConnect.fetchRoadScoreStreetTop10()
      .then((dataRoad) => {
        if (dataRoad && dataRoad.length > 0) {
          const groupedData = dataRoad.reduce((groups, item) => {
            const { road_name, city } = item;
            if (!groups[city]) {
              groups[city] = {};
            }
            if (!groups[city][road_name]) {
              groups[city][road_name] = [];
            }
            groups[city][road_name].push(item);
            return groups;
          }, {});

          const columnCategories = [
            'full_percentile_score',
            'full_percentile_accidents_per_m',
            'full_percentile_concerns_per_m',
            'full_percentile_damages_per_m',
            'full_percentile_quality_total',
            'full_percentile_v_score_v2',
            'full_percentile_anomaly',
            'full_percentile_time_loss',
            'full_percentile_daily_traffic',
          ];

          const columnLists = {};

          columnCategories.forEach((category) => {
            const cityData = groupedData[scoreTown];
            if (cityData) {
              const averagedData = Object.keys(cityData).map((roadName) => {
                const group = cityData[roadName];
                const total = group.reduce(
                  (sum, item) => sum + item[category],
                  0
                );
                const average = (total / group.length).toFixed(2);
                return { road_name: roadName, avg_value: average };
              });

              const lowestAverages = averagedData.slice().sort(
                (a, b) => a.avg_value - b.avg_value
              );
              const top5LowestAverages = lowestAverages.slice(0, 5);

              const highestAverages = averagedData.slice().sort(
                (a, b) => b.avg_value - a.avg_value
              );
              const top5HighestAverages = highestAverages.slice(0, 5);

              columnLists[`${category}_lowest`] = top5LowestAverages;
              columnLists[`${category}_highest`] = top5HighestAverages;
            }
          });

          setTableScore(columnLists);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [scoreTown]);

  // Kategorien für die Straßenscore-Tabelle aus dem Fetch
  const columnCategories = [
    'full_percentile_score',
    'full_percentile_accidents_per_m',
    'full_percentile_concerns_per_m',
    'full_percentile_damages_per_m',
    'full_percentile_quality_total',
    'full_percentile_v_score_v2',
    'full_percentile_anomaly',
    'full_percentile_time_loss',
    'full_percentile_daily_traffic',
  ];

  return (
    <div className="margAcc row">
      {/* Stadtauswahl*/}
      <Dropdown>
        <Dropdown.Toggle className="col stadtdropdown" onMouseDown={(e) => e.stopPropagation()}>
          Wähle eine Stadt: {scoreTown}
        </Dropdown.Toggle>
        <Dropdown.Menu onMouseDown={(e) => e.stopPropagation()}>
          <Dropdown.Item
          
            onClick={() => { setScoreTown("Oldenburg"); }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            Oldenburg
          </Dropdown.Item>

          <Dropdown.Item
        
            onClick={() => { setScoreTown("Osnabrück"); }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            Osnabrück
          </Dropdown.Item>

          <Dropdown.Item
         
            onClick={() => { setScoreTown("Köln"); }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            Köln
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
      <hr></hr>
      {/*Kennzahlauswahl*/}

      <Tab.Container id="tabs" defaultActiveKey={columnCategories[0]} className="row">
        <Nav variant="tabs" className="navrank desktop" >
          {columnCategories.map((category) => (
            <Nav.Item key={category} className="ranktab">
              <Nav.Link eventKey={category} onMouseDown={(e) => e.stopPropagation()}>{subjectTranslations[category]}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        {/* mobile Kennzahlauswahl*/}

        <Dropdown className="onlyMobile col" as={Nav.Item}>
          <Dropdown.Toggle className="col">
            Kategoriewahl
          </Dropdown.Toggle>

          <Dropdown.Menu >
            {columnCategories.map((category) => (
              <Dropdown.Item
                eventKey={category}
                active={columnCategories[0]}
                className="dropdown-category"
              >
                {subjectTranslations[category]}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Tab.Content >
          {columnCategories.map((category) => (
            <Tab.Pane key={category} eventKey={category}>
              {/* Flop 5 Liste*/}
              <div className="rankingtext">
                <h3>{`Flop 5 ${subjectTranslations[category]} für ${scoreTown} `}<img className="col" src={Schlecht} alt="Schlecht Icon" /></h3>
                {tableScore && tableScore[`${category}_highest`] && (
                  <div className="row ">
                    <div className="col">
                      <ol>
                        {tableScore[`${category}_highest`].map((item, index) => (
                          <li key={index} className="rankinglist"><p className="street">{`${index + 1}. ${item.road_name} `}</p><p className="avgvalue">{item.avg_value}</p></li>
                        ))}
                      </ol>
                    </div>
                  </div>
                )}
              </div>
              <div className="rankingtext">
                {/* Top 5 Liste*/}
                <h3>{`Top 5  ${subjectTranslations[category]} für ${scoreTown} `}<img className="col" src={Gut} alt="Schlecht Icon" /></h3>
                {tableScore && tableScore[`${category}_lowest`] && (
                  <div className="row">
                    <div className="col">
                      <ol>
                        {tableScore[`${category}_lowest`].map((item, index) => (
                          <li key={index} className="rankinglist"><p className="street">{`${index + 1}. ${item.road_name} `}</p><p className="avgvalue">{item.avg_value}</p></li>
                        ))}
                      </ol>
                    </div>
                  </div>
                )}
              </div>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default RoadScore_Tables;