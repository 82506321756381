import {Dropdown} from "react-bootstrap";
import React from "react";
import {emptyCheck, getOverallTopicForTopic} from "../../functions/ApiPickerFunctions";
import {getOverallTopics, getTopics} from "../../functions/Translations";

const StepCategory = ({overallTopic, topic, road, updateOverallTopic, updateTopic, updateRoad, nextStep, roads, all}) => {
    const overallTopics = getOverallTopics(all);
    const topics = getTopics();

    return (
        <div className="flex">
            <div className="adjust">
                <Dropdown id="sdfsdsdf adjust">
                    <Dropdown.Toggle id="topicButton" className="dropdown-api-picker shadow-none">
                        Kategorie wählen
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Object.keys(overallTopics).map((value) => (
                            <Dropdown.Item name={overallTopics[value]} eventKey={value}
                                           onClick={() => {
                                               if (value !== overallTopic) {
                                                   updateOverallTopic(value);
                                                   updateTopic("");
                                                   updateRoad("");
                                                   emptyCheck();
                                                   if (all) {
                                                       nextStep();
                                                   }
                                               }
                                           }}
                                           className="dropdown-api-picker-item">
                                {overallTopics[value]}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <div id="overalltopicButton" className="row">
                    <p id="emptycheck" className="col" style={{color: overallTopic !== "" ? "#6c9c34" : "red"}}>
                        {overallTopic !== "" ? overallTopics[overallTopic] : "Angabe fehlt"}
                    </p>
                </div>
            </div>

            <div className="adjust" hidden={all}>
                <Dropdown id="sdfsdsdf">
                    <Dropdown.Toggle disabled={overallTopic === ""} id="topicButton"
                                     className="dropdown-api-picker shadow-none">
                        Kennzahl wählen
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {Object.keys(topics).map((value) => (
                            <Dropdown.Item hidden={getOverallTopicForTopic(value, overallTopics) !== overallTopic}
                                           name={topics[value]}
                                           onClick={() => {
                                               if (value !== topic) {
                                                   updateTopic(value);
                                                   updateRoad("");
                                                   emptyCheck();
                                                   if (value !== "bicycle-volume") {
                                                       nextStep();
                                                   }
                                               }
                                           }}
                                           eventKey="bicycle-volume" className="dropdown-api-picker-item">
                                {topics[value]}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <div id="topicButton" className="row">
                    <p id="emptycheck" className="col" style={{color: topic !== "" ? "#6c9c34" : "red"}}>
                        {topic !== "" ? topics[topic] : "Angabe fehlt"}
                    </p>
                </div>
            </div>

            <div className="adjust" hidden={topic !== "bicycle-volume" || overallTopic !== "bicycle-volume"}>
                <Dropdown>
                    <Dropdown.Toggle id="streetButton" className="dropdown-api-picker shadow-none">
                        Zählstelle wählen
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {roads.map((value) => (
                            <Dropdown.Item onClick={() => {
                                updateRoad(value);
                                emptyCheck();
                                nextStep();
                            }} eventKey={value} name={value} className="dropdown-api-picker-item">
                                {value}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                <div id="streetButton" className="row">
                    <p id="emptycheck" className="col" style={{color: road !== "" ? "#6c9c34" : "red"}}>
                        {road !== "" ? road : "Angabe fehlt"}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default StepCategory;