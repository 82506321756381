import React from "react";
import logo from "../../../images/logo.png";
import ClipLoader from "react-spinners/ClipLoader";

function LoadingView({loadingSpin}) {

    return (
        <>
            {loadingSpin && (
                <div className="sweet-loading overlay-modal">
                    <img src={logo} alt="Logo" className="logo"/>
                    <ClipLoader color={"#6c9c34"} loading={loadingSpin} size={150} aria-label="Loading Spinner"
                                data-testid="loader"/>
                </div>
            )}
        </>
    );
}

export default LoadingView;