import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {fetchNodesData} from "../../functions/MapFunctions";
import {getNodeStateProperties} from "../../functions/Translations";

function NodesLayer({nodeState, nodeChecked, mapBounds, nodesData, loadingSpin, updateNodesData, updateLoadingSpin}) {

    const nodeStateProperties = getNodeStateProperties();

    const useNodesData = useCallback(() => {
        const property = nodeStateProperties[nodeState];

        fetchNodesData(property, mapBounds, updateNodesData, updateLoadingSpin);
    }, [mapBounds, nodeState]);

    useEffect(() => {
        if (nodeChecked) {
            useNodesData();
        }
    }, [mapBounds, nodeChecked, nodeState]);

    return (
        <LayersControl.Overlay name="2: Knotenwerte" checked={nodeChecked}>
            <LayerGroup>
                {/* v1-Score anzeigen */}
                {nodeState === "V1" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((i) => {
                    const node = nodesData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', "yellow", 'red']);

                    return (
                        <Polyline
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={colormap((node.v_score_v1 + 1) * 0.5)} //scaling: [-1;1,64] -> [0,1]
                            fillOpacity={0.6}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen: " + (node.v_score_v1 ? node.v_score_v1.toFixed(4) : 0)}<br></br>
                                {"Gut [-1/1,64] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* v2-Score anzeigen */}
                {nodeState === "V2" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((i) => {
                    const node = nodesData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', "yellow", 'red']);

                    return (
                        <Polyline
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={colormap((node.v_score_v2 + 1) * 0.3788)} //scaling: [-1;1,64] -> [0,1]
                            fillOpacity={0.6}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen unter Berücksichtigung der Geschwindigkeit: " + ((node.v_score_v2 === null) ? 0 : node.v_score_v2.toFixed(4)) + " | Gut [-1;1,64] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* Anomaly-Score anzeigen */}
                {nodeState === "Anomaly" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((i) => {
                    const node = nodesData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', "yellow", 'red']);
                    return (
                        <Polyline
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={colormap(node.anomaly)} //no scaling needed: [0,1]
                            fillOpacity={0.6}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Anomalien: " + ((node.anomaly === null) ? 0 : node.anomaly.toFixed(4)) + " | Gut [0;1] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* TimeLoss-Score anzeigen */}
                {nodeState === "TimeLoss" && !loadingSpin && nodesData.length > 0 && Object.keys(nodesData).map((i) => {
                    const node = nodesData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', "yellow", 'red']);

                    return (
                        <Polyline
                            positions={node.geometry.coordinates}
                            color="black"
                            weight={2}
                            fill={true}
                            fillColor={colormap(node.time_loss / 30)} //scaling: [0,30] -> [0,1]
                            fillOpacity={0.6}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Zeitverlust: " + ((node.time_loss === null) ? 0 : node.time_loss.toFixed(4)) + " | Gut [0;30] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default NodesLayer;