import React from "react";
import {getDayTimes, getTimeSpans} from "../../functions/Translations";
import {getTimeSpansForTimeDimension} from "../../functions/ApiPickerFunctions";
import MyDatePicker from "./MyDatePicker";
import MyTimePicker from "./MyTimePicker";
import {handleDateButtonClick, handleTimeButtonClick} from "../../functions/Time";
import dayjs from "dayjs";

const StepTimeSpan = ({start, updateStart, end, updateEnd, timeDimension, nextStep}) => {
    const timeSpans = getTimeSpans();
    const dayTimes = getDayTimes();

    return (
        <div>
            <p hidden={timeDimension !== ""}>
                <strong>Zeitdimension fehlt! Bitte unter Schritt 3 wählen.</strong>
            </p>
            {timeDimension !== "" &&
                <div>
                    <p className="auswahlTitel col-12">Wähle einen Zeitraum:</p>
                    <div id="timespanButton" className="row col-12">
                        <div id="timespanButton" className="row">
                            <p id="emptycheck" className="col" style={{color: start.year() && end.year() !== dayjs('2000-01-01').year() ? "#6c9c34" : "red"}}>
                                {start.year() && end.year() !== dayjs('2000-01-01').year() ? start.format("DD.MM.YYYY") + "  -  " + end.format("DD.MM.YYYY") : "Angabe fehlt"}
                            </p>
                        </div>
                    </div>
                    <div className="row topdaybuttonBox">
                        {getTimeSpansForTimeDimension(timeDimension, timeSpans).map((value) => (
                            <button className="col" onClick={() => {
                                handleDateButtonClick(value, start, end, updateStart, updateEnd);
                                if (!(timeDimension === "hour" || timeDimension === "quarter")) {
                                    nextStep();
                            }}}>
                                {timeSpans[value]}
                            </button>
                        ))}
                    </div>
                    <div className="row topdaybuttonBox">
                        <MyDatePicker date={start} updateDate={updateStart} label="Startdatum"/>
                        <MyDatePicker date={end} updateDate={updateEnd} label="Enddatum"/>
                    </div>

                    {(timeDimension === "hour" || timeDimension === "quarter") &&
                        <div className="mt-4">
                            <p className="auswahlTitel col-12">Wähle einen Tageszeitraum:</p>
                            <div id="timespanButton" className="row col-12">
                                <div id="timespanButton" className="row">
                                    <p id="emptycheck" className="col" style={{color: start.format("HH:mm") !== end.format("HH:mm") ? "#6c9c34" : "red"}}>
                                        {start.format("HH:mm") + "  -  " + end.format("HH:mm") + " Uhr"}
                                    </p>
                                </div>
                            </div>
                            <div className="row topdaybuttonBox">
                                {Object.keys(dayTimes).map((value) => (
                                    <button className="col" onClick={() => {
                                        handleTimeButtonClick(value, start, end, updateStart, updateEnd);
                                        if (start.year() && end.year() !== dayjs('2000-01-01').year()) {
                                            nextStep();
                                        }
                                    }}>
                                        {dayTimes[value]}
                                    </button>
                                ))}
                            </div>
                            <div className="row topdaybuttonBox">
                                <MyTimePicker date={start} updateDate={updateStart} label="Startzeit"/>
                                <MyTimePicker date={end} updateDate={updateEnd} label="Endzeit"/>
                            </div>
                        </div>}
                </div>}
        </div>
    );
};

export default StepTimeSpan;