import {Button} from "react-bootstrap";
import React from "react";
import {getCities} from "../../functions/Translations";
import {emptyCheck} from "../../functions/ApiPickerFunctions";

const StepCity = ({city, updateCity, nextStep}) => {
    const cities = getCities();

    return (
        <div>
            <p className="auswahlTitel col-12">Wähle eine Stadt:</p>
            <div id="cityButton" className="buttonbox row">
                <p id="emptycheck" className="col" style={{color: city !== "" ? "#6c9c34" : "red"}}>
                    {city !== "" ? cities[city] : "Angabe fehlt"}
                </p>
            </div>
            <div className="row buttonbox">
                {Object.keys(cities).map((value) => (
                    <Button className="btn btn-primary col-12"
                            onClick={() => { updateCity(value); emptyCheck(); nextStep(); }}>
                        {cities[value]}
                    </Button>))}
            </div>
        </div>
    );
};

export default StepCity;