import React, { useState, useEffect } from "react";
import { GeoJSON } from "react-leaflet";
import ClipLoader from "react-spinners/ClipLoader";
import L from "leaflet";
import osmtogeojson from "osmtogeojson";
import ApiConnection from "../dashboard/api/ApiConnection";
import BundeslaenderDropdown from "./BundeslaenderDropdown";
import logo from '../../images/logo.png';
import { booleanPointInPolygon } from '@turf/turf';


const PolygonNet = (markerProp) => {
    const [boundaries, setBoundaries] = useState([]);
    const [loadingSpin, setLoadingSpin] = useState(true);
    const [selectedStates, setSelectedStates] = useState(["Niedersachsen", "Bremen"]);
    const [geoJSONKey, setGeoJSONKey] = useState(0);
    const [calculatedValues, setCalculatedValues] = useState({}); //Zwischenspeicher

    const handleBundeslaenderSelect = (selectedStates) => {
        setSelectedStates(selectedStates);
    };

    useEffect(() => {
        setGeoJSONKey(prevKey => prevKey + 1);
    }, [boundaries]);

    const fetchStateBoundaries = async (selectedStates) => {
        setLoadingSpin(true);
        let queries = [];

        if (selectedStates.includes("Deutschland") || selectedStates.includes("Nachbarländer")) {
            // Reset queries array
            queries = [];
            if (selectedStates.includes("Deutschland")) {
                const genericQuery = `[out:json][timeout:25];            (
                area["name"="Deutschland"]["boundary"="administrative"];
                );(rel(area)["admin_level"="4"]["boundary"="administrative"];);out geom;
              `;
                queries.push(genericQuery);

                if (selectedStates.includes("Nachbarländer")) {
                    queries.push(`
              (
                rel(2323309);
                rel(50046);
                rel(51701);
                rel(16239);
                rel(2202162);
                rel(52411);
                rel(49715);
                rel(2171347);
                rel(51684);
              );
              out geom;`);
                }
            }
            else {
                const genericQuery = `[out:json][timeout:25];
                (
                    rel(2323309);
                    rel(50046);
                    rel(51701);
                    rel(16239);
                    rel(2202162);
                    rel(52411);
                    rel(49715);
                    rel(2171347);
                    rel(51684);
                  );
                  out geom;`;
                queries.push(genericQuery);
            }
        }
        else {

            const genericStates = selectedStates.filter(state => state !== "Hamburg" && state !== "Berlin");
            if (genericStates.length > 0) {
                const genericQuery = `[out:json][timeout:25];
                (
                  ${genericStates.map(state => `area["name"="${state}"]["boundary"="administrative"];`).join("\n")}
                );
                (
                  rel(area)["admin_level"="6"]["boundary"="administrative"];
                );
                out geom;`;
                queries.push(genericQuery);
            }

            // Handle specific cases for "Hamburg" and "Berlin"
            if (selectedStates.includes("Hamburg")) {
                queries.push(`
              (rel(62782); );
              out geom;`);
            }

            if (selectedStates.includes("Berlin")) {
                queries.push(`(rel(62422); );
              out geom;`);
            }
        }
        // Concatenate all queries into one string
        const combinedQuery = queries.join("\n");
        console.log(combinedQuery);

        const apiUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(combinedQuery)}`;

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();
            const geojson = osmtogeojson(data);

            // Filter out "Point"
            const filteredFeatures = geojson.features.filter(feature => feature.geometry.type !== "Point");

            const filteredGeoJSON = {
                type: "FeatureCollection",
                features: filteredFeatures
            };
            setBoundaries(filteredGeoJSON);
            setLoadingSpin(false);
        } catch (error) {
            console.error('Error fetching state boundaries:', error);
            setLoadingSpin(false);
        }
    };


    useEffect(() => {
        fetchStateBoundaries(selectedStates);
    }, [selectedStates]);

    // Define custom icon with iconUrl property set
    const customIcon = new L.Icon({
        iconUrl: require('../../images/road-circle-exclamation-solid.svg').default,
        iconSize: [15, 15],
        iconAnchor: [12, 12],
    });

    const parsePolygonString = (polygon, polytype) => {

        return {
            type: "Feature",
            geometry: {
                type: polytype,
                coordinates: polygon
            }
        };
    };

    const countMarkersWithinPolygon = (type, polygon) => {

        let markerCount = 0;
        if (type === "Polygon" || type === "MultiPolygon") {
            const polyTransform = parsePolygonString(polygon, type);
            const markerData = markerProp;
            markerData.markerProp.forEach(marker => {
                let markerpoint = [marker.longitude, marker.latitude];
                if (booleanPointInPolygon(markerpoint, polyTransform)) {
                    markerCount++;
                }
            });
        }
        //console.log(markerCount);
        return markerCount;
    };


    function colorPoly(count) {
        const opacity = 0.4;
        if (count > 2000) return `rgba(255,0,0,${opacity})`; // Red
        if (count > 1500) return `rgba(255,100,0,${opacity})`; // Red-Orange
        if (count > 1000) return `rgba(255,165,0,${opacity})`; // Orange
        if (count > 500) return `rgba(255,200,0,${opacity})`; // Yellow-Orange
        if (count > 100) return `rgba(255,255,0,${opacity})`; // Yellow
        if (count > 20) return `rgba(0,100,0,${opacity})`; // Dark Green
        if (count > 0) return `rgba(0,100,0,0.2)`; // Green
        return `rgba(255,255,255,${opacity})`; // Transparent
    }
     

    return (
        <>
            {loadingSpin ? (
                <div className="sweet-loading overlay-container">
                    <img src={logo} alt="Logo" className="logo" />
                    <p className="loading-text">Übersicht wird geladen</p><br />
                    <ClipLoader
                        color={"#6c9c34"}
                        loading={loadingSpin}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : (
                boundaries.features && markerProp && (
                    <>
                        <BundeslaenderDropdown onSelect={handleBundeslaenderSelect} activeStates={selectedStates} />
                        <GeoJSON
                            key={geoJSONKey}
                            data={boundaries}
                            onEachFeature={(feature, layer) => {
                                // Überprüfe, ob die berechneten Werte bereits im Cache vorhanden sind
                                if (calculatedValues[feature.properties.name]) {
                                    // Verwende die im Cache gespeicherten Werte
                                    const { markersCountWithinPolygon, fillColor } = calculatedValues[feature.properties.name];
                                    feature.properties.markersCountWithinPolygon = markersCountWithinPolygon;
                                    layer.setStyle({
                                        fillColor: fillColor,
                                        fillOpacity: 1,
                                        weight: 2,
                                        opacity: 1,
                                        dashArray: 3,
                                        color: '#6c9c34'
                                    });
                                } else {
                                    // Berechne die Werte neu und speichere sie im Cache
                                    const markersCountWithinPolygon = countMarkersWithinPolygon(feature.geometry.type, feature.geometry.coordinates);
                                    const fillColor = colorPoly(markersCountWithinPolygon);
                                    setCalculatedValues(prevState => ({
                                        ...prevState,
                                        [feature.properties.name]: {
                                            markersCountWithinPolygon,
                                            fillColor
                                        }
                                    }));
                                    feature.properties.markersCountWithinPolygon = markersCountWithinPolygon;
                                    layer.setStyle({
                                        fillColor: fillColor,
                                        fillOpacity: 1,
                                        weight: 2,
                                        opacity: 1,
                                        dashArray: 3,
                                        color: '#6c9c34'
                                    });
                                }
                                layer.bindTooltip(`<br />Gebiet: ${feature.properties.name}<br />Anzahl Meldungen: ${feature.properties.markersCountWithinPolygon}<br />`);
                            }}
                        />
                    </>
                ))}
        </>
    );
}
export default PolygonNet;