import React, { useState, useEffect } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';

/**
 * Komponente für die Adresssuchleiste zur Eingabe und Auswahl von Adressen.
 * @param {Function} onAddressSelected - Callback-Funktion, die aufgerufen wird, wenn eine Adresse ausgewählt wurde.
 */
function AddressSearchBar({ onAddressSelected, onZoomToAddress }) {
  const [query, setQuery] = useState(''); // Zustand für den eingegebenen Suchbegriff
  const [suggestions, setSuggestions] = useState([]); // Zustand für die Vorschläge
  const provider = new OpenStreetMapProvider( {params: {
    'accept-language': 'de', // nur deutsche Ergebnisse
    countrycodes: 'de' // nur deutsche Ergebnisse
  },
}); // Instanz des OpenStreetMap-Providers

 
  /**
   * Funktion, die aufgerufen wird, wenn sich der Wert der Eingabe ändert.
   * @param {Object} e - Event-Objekt
   */
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length >= 3) {
      fetchSuggestions(value); // Vorschläge holen, wenn Suchbegriff länger als 2 Zeichen ist
    } else {
      setSuggestions([]); // Vorschläge zurücksetzen, wenn Suchbegriff zu kurz ist
    }
  };

  /**
   * Funktion, die Vorschläge von OpenStreetMap holt und in den Zustand speichert.
   * @param {string} value - Suchbegriff
   */
  const fetchSuggestions = async (value) => {
    try {
      const results = await provider.search({ query: value, limit: 5, countrycodes: 'de' });
      const sortedSuggestions = sortSuggestions(results);
      setSuggestions(sortedSuggestions);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Funktion zum Sortieren der Vorschläge.
   * @param {Array} results - Ergebnisse der OpenStreetMap-Suche
   * @returns {Array} - Sortierte Vorschläge als Array von Strings
   */
  const sortSuggestions = (results) => {
    return results.map((result) => result.label);
  };

  /**
   * Funktion, die aufgerufen wird, wenn ein Vorschlag ausgewählt wurde.
   * @param {string} suggestion - Ausgewählter Vorschlag
   */
  const handleSuggestionClick = async (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);

    try {
      const results = await provider.search({ query: suggestion });
      if (results.length > 0) {
        const { x, y } = results[0];
        let city = results[0].raw.addresstype;
        onAddressSelected({ lat: y, lon: x }); // Callback-Funktion aufrufen und Koordinaten übergeben
        let zoomLevel;

        // Determine the appropriate zoom level based on the type of result
        if (city === 'city') {
          zoomLevel = 14;
        } else if (city === 'town') {
          zoomLevel = 15;
        } else if (city === 'road') {
          zoomLevel = 18;
        } 
        else {
          zoomLevel = 16; 
        }

           // Call the onZoomToAddress callback function to update map center and zoom
        onZoomToAddress({ lat: y, lon: x }, zoomLevel);
      } else {
        throw new Error('Adresse nicht gefunden');
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Effekt-Hook zum Hinzufügen und Entfernen des Event Listeners für die Enter-Taste.
   */
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        // Die gewünschte Logik direkt hier ausführen
        const selectedSuggestion = suggestions[0]; // Annahme: Die erste Vorschlagsoption wird verwendet
        if (selectedSuggestion) {
          handleSuggestionClick(selectedSuggestion);
        } else {
          // Hier kannst du eine alternative Aktion ausführen, wenn keine Vorschläge vorhanden sind.
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyPress);
  
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [suggestions]);

  const clearInput = () => {
    setQuery('');
    setSuggestions([]);
  };

  return (
    <div className='address-search'>
           <input
        type='text'
        value={query}
        onChange={handleInputChange}
        placeholder='Zu Stadt / Adresse springen'
      />
        {suggestions.length > 0 && (
        <ul className='suggestion-list'>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
           <li className='clear-button' onClick={() => clearInput()}>
            Zurücksetzen
        </li>
        </ul>
      )}
    </div>
  );
}

export default AddressSearchBar;