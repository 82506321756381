import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {scoreOptions} from "../../functions/FilterFunctions";

function FilterRoadScore({scoreState, updateScoreState, updateLoadingSpin}) {

    return (
        <div>
            {scoreOptions.map((roadScoreType, index) => (
                <button key={index} className={`col-12 filterBtn ${scoreState === roadScoreType ? "selected" : ""}`}
                        onClick={() => { updateScoreState(roadScoreType); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container" >
                                <img src={scoreState === roadScoreType ? checked : unchecked}
                                     alt={scoreState === roadScoreType ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {roadScoreType}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterRoadScore;