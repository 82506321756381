import ConnectionError from "../api/ConnectionError";

export function dataNotAvailable()  {
    alert("Für diesen Zeitraum existieren keinen Daten.");
}

export function dataZero(word) {
    alert("Für diesen Zeitraum gibt es keine " + word + ".");
}

export function errorHandling(error) {
    if (error instanceof ConnectionError) {
        alert("Fehler bei der Verbindung zur Datenbank.")
    } else {
        console.log(error);
        alert("Fehler bei der Datenauswahl. Bitte an die Entwickler melden.")
    }
}