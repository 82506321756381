import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {rddIcon} from "../../functions/Icons";
import {fetchReportPlatformData} from "../../functions/MapFunctions";

function DamageReportsLayer({reportChecked, reportedImg, startDate, endDate, mapBounds, loadingSpin,
                                updateReportedImg, updateLoadingSpin}) {

    const useReportPlatformData = useCallback(() => {
        fetchReportPlatformData(startDate, endDate, mapBounds, updateReportedImg, updateLoadingSpin);
    }, [startDate, endDate, mapBounds]);

    useEffect(() => {
        if (reportChecked) {
            useReportPlatformData();
        }
    }, [startDate, endDate, mapBounds, reportChecked]);

    return (
        <LayersControl.Overlay name="12: RDD(Schadensmeldungen)" checked={reportChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(reportedImg).map(annotation_id => {
                    const image = reportedImg[annotation_id];

                    return (
                        <Marker key={annotation_id} position={[image.latitude, image.longitude]} icon={rddIcon}>
                            <Popup>
                                <strong>aufgenommen am:</strong> {image.create_date} <br />
                                <strong>erkannter Schaden:</strong> {image.classification ? image.classification : "k.A."} <br />
                                <strong>Sicherheit:</strong> {image.confidence ? image.confidence.toFixed(2) : "k.A."} <br />
                            </Popup>
                        </Marker>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default DamageReportsLayer;