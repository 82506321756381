import {errorHandling} from "./Errors";
import {ApiConnect} from "../Dashboard";
import {
    accidentCountPeopleTime,
    accidentCountPeopleType,
    accidentGround,
    accidentPerpetrator,
    accidentTemperature,
    accidentTime,
    accidentTypes,
    accidentWeather,
    bikeTraffic,
    bikeTrafficAllStreets,
    concernsDuration,
    concernsTime,
    concernsType,
    concernsTypeProblem,
    moneyDamagePerAccidentPerpetrator,
    avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount,
    moneyDamagePerAccidentPerpetratorWithPeopleDamage,
    AvgMoneyDamagePerAccidentPerpetratorWithPeopleDamage
} from "./ApiFunctions";
import ConnectionError from "../api/ConnectionError";
import {getStandard} from "../templates/Standard";
import {getConcerns} from "../templates/Concerns";
import {getAccidents} from "../templates/Accidents";
import {getBicycleVolume} from "../templates/BicycleVolume";

export async function handleSubmit(event, city, overallTopic, topic, road, timeDimension, start, end, boxIndex,
                                   updateBoxInBoxes) {
    event.preventDefault();

    if ((overallTopic === "" || topic === "" || timeDimension === "" || start === "" || end === "" || city === "") ||
        (topic === "bicycle-volume" && road === "")) {
        alert("Bitte fehlende Angaben ergänzen!");
        return;
    }

    switch (topic) {
        case "bicycle-volume":
            ApiConnect.fetchBikeTraffic(timeDimension, road, [start, end])
                .then((data) => {
                    const newBox = bikeTraffic(data, city, start, end, timeDimension, topic, overallTopic, road);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "bicycle-volume-all-streets":
            ApiConnect.fetchBikeTrafficAllStreets(city, [start, end])
                .then((data) => {
                    const newBox = bikeTrafficAllStreets(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-type":
            ApiConnect.fetchAccidentTypes(city, [start, end])
                .then((data) => {
                    const newBox = accidentTypes(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-time":
            ApiConnect.fetchAccidentTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = accidentTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-people-type":
            ApiConnect.fetchAccidentCountPeopleType(city, [start, end])
                .then((data) => {
                    const newBox = accidentCountPeopleType(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-people-time":
            ApiConnect.fetchAccidentCountPeopleTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = accidentCountPeopleTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                })
                .catch((error) => {
                    errorHandling(error);
                });
            break;


        case "accidents-perpetrator":
            ApiConnect.fetchAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = accidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-road-condition":
            ApiConnect.fetchAccidentGround(city, [start, end])
                .then((data) => {
                    const newBox = accidentGround(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-weather":
            ApiConnect.fetchAccidentWeather(city, [start, end])
                .then((data) => {
                    const newBox = accidentWeather(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-temperature":
            Promise.all([
                ApiConnect.fetchAccidentTempLowerMinus10(city, [start, end]),
                ApiConnect.fetchAccidentTempMinus10to0(city, [start, end]),
                ApiConnect.fetchAccidentTemp0to10(city, [start, end]),
                ApiConnect.fetchAccidentTemp10to20(city, [start, end]),
                ApiConnect.fetchAccidentTemp20to30(city, [start, end]),
                ApiConnect.fetchAccidentTempHigher30(city, [start, end])
            ])
                .then(([dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30]) => {
                    const newBox = accidentTemperature(dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30,
                        city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "accidents-monetary-damage":
            ApiConnect.fetchMoneyDamagePerAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = moneyDamagePerAccidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;
        
        case "avg-accidents-monetary-damage":
                ApiConnect.fetchAvgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(city, [start, end])
                    .then((data) => {
                        const newBox = avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(data, city, start, end, timeDimension, topic, overallTopic);
                        updateBoxInBoxes(newBox, boxIndex);
                    }).catch((error) => {
                    errorHandling(error);
                });
                break;

        case "accidents-monetary-damage-people":
                    ApiConnect.fetchDamagePerAccidentPerpetrator(city, [start, end])
                        .then((data) => {
                            const newBox = moneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic);
                            updateBoxInBoxes(newBox, boxIndex);
                        }).catch((error) => {
                        errorHandling(error);
                    });
                    break;

        case "avg-accidents-monetary-damage-people":
                        ApiConnect.fetchDamagePerAccidentPerpetrator(city, [start, end])
                            .then((data) => {
                                const newBox = AvgMoneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic);
                                updateBoxInBoxes(newBox, boxIndex);
                            }).catch((error) => {
                            errorHandling(error);
                        });
                        break;        

        case "concerns-time":
            ApiConnect.fetchConcernsTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = concernsTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "concerns-duration":
            ApiConnect.fetchConcernsDuration(city, [start, end])
                .then((data) => {
                    const newBox = concernsDuration(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "concerns-type":
            ApiConnect.fetchConcernsType(city, [start, end])
                .then((data) => {
                    const newBox = concernsType(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "concerns-type-problem":
            ApiConnect.fetchConcernsTypeProblem(city, [start, end])
                .then((data) => {
                    const newBox = concernsTypeProblem(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        default:
            break;
    }
}

export async function handleTemplateSubmit(event, city, overallTopic, start, end, roads, addBoxes) {
    event.preventDefault();

    if (overallTopic === "" || start === "" || end === "" || city === "") {
        alert("Bitte fehlende Angaben ergänzen!");
        return;
    }

    switch (overallTopic) {
        case ("standard"):
            await getStandard(city, start, end, addBoxes);
            break;
        case ("bicycle-volume"):
            await getBicycleVolume(city, start, end, roads, addBoxes);
            break;
        case ("accidents"):
            await getAccidents(city, start, end, addBoxes);
            break;
        case ("concerns"):
            await getConcerns(city, start, end, addBoxes);
            break;
        default:
            alert("Ausgabe nicht möglich");
            break;
    }
}