import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {nodeConditionsOptions, nodeConditionsTranslations} from "../../functions/FilterFunctions";

function FilterNodeState({nodeState, updateNodeState, updateLoadingSpin}) {

    return (
        <div>
            {nodeConditionsOptions.map((nodeType, index) => (
                <button
                    key={index}
                    className={`col-12 filterBtn ${nodeState === nodeType ? "selected" : ""}`}
                    onClick={() => { updateNodeState(nodeType); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container">
                                <img src={nodeState === nodeType ? checked : unchecked}
                                     alt={nodeState === nodeType ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {nodeConditionsTranslations[nodeType]}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterNodeState;