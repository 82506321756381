import {Modal} from "react-bootstrap";
import {Step, Stepper} from "react-form-stepper";
import React from "react";

const ModalHeader = ({headers, activeStep, updateStep}) => {
    return (
        <Modal.Header className='row'>
            <Modal.Title>
                <Stepper activeStep={activeStep}>
                    {headers.map((header, index) => (
                        <Step style={{backgroundColor: index === activeStep ? (header ? "#6c9c34" : "red")
                                : (header ? "#6b9c34be" : "rgba(255, 0, 0, 0.767)")}}
                              onClick={() => updateStep(index)}/>
                    ))}
                </Stepper>
            </Modal.Title>
        </Modal.Header>
    );
}

export default ModalHeader;