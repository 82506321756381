import React from "react";
import {Accordion} from "react-bootstrap";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {filterWeatherOptions, weatherConditionsTranslations} from "../../functions/FilterFunctions";

function AccordionWeather({filterWeather, removeFilter, filterFunctionExtra}) {

    return (
        <Accordion>
            <Accordion.Item eventKey="wetter">
                <Accordion.Header>Wetter</Accordion.Header>
                <Accordion.Body>
                    <div className="col">
                        <button className={`col-12 resetBtn`} onClick={() => removeFilter("wetter")}>
                            Zurücksetzen
                        </button>
                        {filterWeatherOptions.map((option, index) => (
                            <button key={index} className={`col-12 filterBtn ${filterWeather.includes(option) ? "selected" : ""}`}
                                    onClick={() => filterFunctionExtra("wetter", option)}>
                              <span className="icon-filter-container">
                                <img src={filterWeather.includes(option) ? checked : unchecked}
                                     alt={filterWeather.includes(option) ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {weatherConditionsTranslations[option]}
                              </span>
                            </button>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionWeather;