import React from "react";
import GraphBlock from "./GraphBlock";

const DetailGraphBlock = ({ type, data, nameKey, dataKey, isOpen, boxNr }) => {
  return (
    <span className="detail__graph">
      <GraphBlock type={type} data={data} nameKey={nameKey} dataKey={dataKey} isOpen={isOpen} boxNr={boxNr}/>
    </span>
  );
};

export default DetailGraphBlock;