import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key]; var y = b[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

function Bar_Chart_StackedZoom(props) {
  const boxnr = props.boxnr;
  console.log(boxnr);
  const mask = "mask-stripe" + boxnr;
  const urlMask = "url(#" + mask + ")";
  const patternStripe = "pattern-stripe" + boxnr;
  const urlPatternStripe = "url(#" + patternStripe + ")";

  const CandyBar2 = (props) => {
    const {
      x: oX,
      y: oY,
      width: oWidth,
      height: oHeight,
      value,
      fill
    } = props;
  
    let x = oX;
    let y = oHeight < 0 ? oY + oHeight : oY;
    let width = oWidth;
    let height = Math.abs(oHeight);
  
    return (
      <rect fill={fill}
        mask={urlMask}
        x={x}
        y={y}
        width={width}
        height={height} />
    );
  };

  let data = Array.from(props.data);
  let colors = Array.from(props.colors);
  colors[3] = colors[2];
  colors[2] = colors[1];

  return (
    <ResponsiveContainer width='99%' height='90%'>
      <BarChart
        data={props.data}
        layout={"vertical"}
        margin={{ top: 5, right: 30, left: 90, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis fontSize={12} type="category" textAnchor="end" sclaeToFit="true" verticalAnchor="start" interval={0} angle="0" dataKey={props.dataX} />
        <Tooltip />
        <Legend />
        <pattern id={patternStripe}
          width="8" height="8"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)">
          <rect width="6" height="8" transform="translate(0,0)" fill="white"></rect>
        </pattern>
        <mask id={mask}>
          <rect x="0" y="0" width="100%" height="100%" fill={urlPatternStripe} />
        </mask>
        {props.dataY.map((dataKey, index) => (
          <Bar dataKey={dataKey} stackId="a" shape={index === 2 ? <CandyBar2 /> : null} fill={colors[index]} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Bar_Chart_StackedZoom;
