import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {carIcon} from "../../functions/Icons";
import {format} from "date-fns";
import {fetchCarStationsData} from "../../functions/MapFunctions";

function CarCountingLayer({carChecked, groupedCarStations, groupedCarStationsOl, town, sD, eD, mapRef, mapBounds,
                              updateMapBounds, updateGroupedCarStations, updateGroupedCarStationsOl, updateLoadingSpin}) {

    const useCarStationsData = useCallback(() => {
        fetchCarStationsData(town, mapRef, sD, eD, mapBounds, updateMapBounds, updateGroupedCarStations,
            updateGroupedCarStationsOl, updateLoadingSpin);
    }, [sD, eD, town]);

    useEffect(() => {
        if (carChecked) {
            useCarStationsData();
        }
    }, [sD, eD, town, carChecked]);

    return (
        <LayersControl.Overlay name="8: Auto Zählstellen" checked={carChecked}>
            {town === "Osnabrueck" && (
                <LayerGroup>
                    {Object.keys(groupedCarStations).map(stationId => {
                        const station = groupedCarStations[stationId];

                        return (
                            <Marker key={stationId} position={[station.latitude, station.longitude]} icon={carIcon}>
                                <Popup>
                                    <strong>Zeitraum: </strong>{format(sD, "dd.MM.yy")} bis {format(eD, "dd.MM.yy")}<br/>
                                    <strong>ID:</strong> {stationId} <strong>Ort:</strong> {station.location} ({station.direction})<br /><br />
                                    <strong>Durch. Geschwindigkeit in Zeitraum:</strong> {station.avg_speed_per_hour_custom.toFixed(2)} km/h <br />
                                    <strong>Max. Geschwindigkeit:</strong> {station.max_speed_per_hour.toFixed(2)} km/h <br /><br />
                                    <strong>Verkehrsaufkommen in Zeitraum:</strong> {station.sum_traffic_volume_custom_range.toFixed(0)}<br />
                                    <strong>Verkehrsaufkommen/h:</strong><br />
                                    Gesamt: {station.hour_avg_traffic_volume.toFixed(0)} / Zeitraum: {station.hour_avg_traffic_volume_range.toFixed(0)}<br />
                                    <strong>Max. Verkehrsaufkommen/h:</strong> <br />
                                    Gesamt:{station.max_traffic_volume.toFixed(0)} / Zeitraum: {station.max_traffic_volume_range.toFixed(0)}<br />
                                    <strong>Verkehrsaufkommen/Tag:</strong> <br />
                                    Gesamt:{station.day_avg_traffic_volume.toFixed(0)} / Zeitraum: {station.day_avg_traffic_volume_range.toFixed(0)}<br />
                                    <strong>Verkehrsaufkommen/Monat:</strong><br />
                                    Gesamt:{station.day_avg_traffic_volume.toFixed(0) * 30} / Zeitraum: {station.day_avg_traffic_volume_range.toFixed(0) * 30}<br /><br />
                                </Popup>
                            </Marker>
                        );
                    })}
                </LayerGroup>
            )}

            {town === "Oldenburg" && (
                <LayerGroup>
                    {Object.keys(groupedCarStationsOl).map(stationId => {
                        const station = groupedCarStationsOl[stationId];

                        return (
                            <Marker key={stationId} position={[station.latitude, station.longitude]} icon={carIcon}>
                                <Popup>
                                    <strong>Zeitraum: </strong>{format(sD, "dd.MM.yy")} bis {format(eD, "dd.MM.yy")}<br/>
                                    <strong>ID:</strong> {stationId}<br /><br />
                                    <strong>Verkehrsaufkommen in Zeitraum:</strong> {station.sum_traffic_volume_custom_ol ? station.sum_traffic_volume_custom_ol.toFixed(0) : 0}<br />
                                    <strong>Verkehrsaufkommen/h:</strong><br />
                                    Zeitraum: {station.hour_avg_traffic_volume_ol ? station.hour_avg_traffic_volume_ol.toFixed(0) : 0} / Gesamt: {station.hour_avg_traffic_volume_ol_gesamt ? station.hour_avg_traffic_volume_ol_gesamt.toFixed(0) : 0}<br />
                                    <strong>Max. Verkehrsaufkommen/h:</strong><br />
                                    Zeitraum: {station.max_traffic_volume_ol ? station.max_traffic_volume_ol.toFixed(0) : 0} / Gesamt: {station.max_traffic_volume_ol_gesamt ? station.max_traffic_volume_ol_gesamt.toFixed(0) : 0}<br />
                                    <strong>Verkehrsaufkommen/Tag:</strong> <br />
                                    Zeitraum: {station.day_avg_traffic_volume_ol ? station.day_avg_traffic_volume_ol.toFixed(0) : 0} / Gesamt: {station.day_avg_traffic_volume_ol_gesamt ? station.day_avg_traffic_volume_ol_gesamt.toFixed(0) : 0}<br />
                                    <strong>Verkehrsaufkommen/Monat:</strong><br />
                                    Zeitraum: {station.day_avg_traffic_volume_ol ? station.day_avg_traffic_volume_ol.toFixed(0) * 30 : 0} / Gesamt: {station.day_avg_traffic_volume_ol_gesamt ? station.day_avg_traffic_volume_ol_gesamt.toFixed(0) * 30 : 0}<br /><br />
                                </Popup>
                            </Marker>
                        );
                    })}
                </LayerGroup>
            )}
        </LayersControl.Overlay>
    );
}

export default CarCountingLayer;