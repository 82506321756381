import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {filterConcernsOptions} from "../../functions/FilterFunctions";

function FilterConcerns({concernActive, removeFilter, filterSetConcerns}) {

    return (
        <div>
            <button className={`col-12 resetBtn`} onClick={() => removeFilter("concern")}>
                Zurücksetzen
            </button>
            {filterConcernsOptions.map((option, index) => (
                <button key={index}
                        className={`col-12 filterBtn ${concernActive.includes(option.keyword) ? "selected" : ""}`}
                        onClick={() => {
                            filterSetConcerns(option.values, option.keyword)
                        }}>
                              <span className="icon-filter-container">
                                <img src={concernActive.includes(option.keyword) ? checked : unchecked}
                                     alt={concernActive.includes(option.keyword) ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {option.keyword}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterConcerns;