import React, {useState, useEffect} from "react";
import '../../../styles/Modal.css';
import {Modal} from "react-bootstrap";
import StepCity from "./modal/StepCity";
import StepCategory from "./modal/StepCategory";
import StepTimeDimension from "./modal/StepTimeDimension";
import StepTimeSpan from "./modal/StepTimeSpan";
import StepOverview from "./modal/StepOverview";
import {handleSubmit} from "../functions/SubmitHandler";
import ModalHeader from "./modal/ModalHeader";
import ModalFooter from "./modal/ModalFooter";
import dayjs from "dayjs";
import {loadStations} from "../functions/ApiFunctions";

const ApiPicker = ({show, handleShow, boxes, updateBoxes, boxIndex}) => {
    const box = boxes[boxIndex];

    const updateBoxInBoxes = (newBox, boxIndex) => {
        const newBoxes = boxes.map((box, index) => {
            if (index === boxIndex) {
                return newBox;
            }
            return box;
        });
        updateBoxes(newBoxes);
    };

    const [city, setCity] = useState(box.city);
    const [overallTopic, setOverallTopic] = useState(box.overallTopic);
    const [topic, setTopic] = useState(box.topic);
    const [road, setRoad] = useState(box.road);
    const [timeDimension, setTimeDimension] = useState(box.timeDimension);
    const [start, setStart] = useState(dayjs(box.start));
    const [end, setEnd] = useState(dayjs(box.end));

    const updateCity = (city) => { setCity(city); }
    const updateOverallTopic = (overallTopic) => { setOverallTopic(overallTopic); }
    const updateTopic = (topic) => { setTopic(topic);}
    const updateRoad = (road) => { setRoad(road); }
    const updateTimeDimension = (timeDimension) => { setTimeDimension(timeDimension); }
    const updateStart = (newValue) => { setStart(newValue); }
    const updateEnd = (newValue) => { setEnd(newValue); }

    const [roads, setRoads] = useState([]);

    const updateRoads = (roads) => { setRoads(roads); }

    useEffect(() => {
        loadStations(city, updateRoads);
    }, [city]);

    const [activeStep, setActiveStep] = useState(0);

    const updateStep = (step) => { setActiveStep(step); }

    const nextStep = () => { updateStep(activeStep !== steps.length-1 ? activeStep + 1 : activeStep); }

    const previousStep = () => { updateStep(activeStep !== 0 ? activeStep - 1 : 0); }

    const resetInput = () => {
        updateCity("");
        updateOverallTopic("");
        updateTopic("");
        updateRoad("");
        updateTimeDimension("");
        updateStart(dayjs('2000-01-01'));
        updateEnd(dayjs('2000-01-01'));
        updateStep(0);
    }

    const cancel = () => {
        updateCity(box.city);
        updateOverallTopic(box.overallTopic);
        updateTopic(box.topic);
        updateRoad(box.road);
        updateTimeDimension(box.timeDimension);
        updateStart(dayjs(box.start));
        updateEnd(dayjs(box.end));
        handleShow();
        updateStep(0);
    }

    const steps = [
        <StepCity city={city} updateCity={updateCity} nextStep={nextStep}/>,
        <StepCategory overallTopic={overallTopic} topic={topic} road={road} updateOverallTopic={updateOverallTopic}
                      updateTopic={updateTopic} updateRoad={updateRoad} nextStep={nextStep} roads={roads} all={false}/>,
        <StepTimeDimension timeDimension={timeDimension} updateTimeDimension={updateTimeDimension} nextStep={nextStep}/>,
        <StepTimeSpan start={start} updateStart={updateStart} end={end} updateEnd={updateEnd}
                      timeDimension={timeDimension} nextStep={nextStep}/>,
        <StepOverview city={city} topic={topic} road={road} timeDimension={timeDimension} start={start} end={end}/>
    ];

    const headers = [
        city !== "",
        overallTopic && topic !== "" && !(topic === "bicycle-volume" && road === ""),
        timeDimension !== "",
        start.year() && end.year() !== dayjs('2000-01-01').year(),
        city && overallTopic && topic && timeDimension !== "" &&
        start.year() && end.year() !== dayjs('2000-01-01').year() &&
        !(topic === "bicycle-volume" && road === "")
    ];

    const disabled = city === "" || overallTopic === "" || topic === "" || timeDimension === "" ||
        start.year() === dayjs('2000-01-01').year() || end.year() === dayjs('2000-01-01').year() ||
        (topic === "bicycle-volume" && road === "");

    return (
        <div className="popup container-fluid adjust">
            <Modal show={show} size={'lg'} centered scrollable>
                <ModalHeader headers={headers} activeStep={activeStep} updateStep={updateStep}/>

                <Modal.Body className="modalStyle">
                    {steps.map((step, index) => (activeStep === index && ( step )))}
                </Modal.Body>

                <ModalFooter previousStep={previousStep} nextStep={nextStep} cancel={cancel} resetInput={resetInput}
                             handleSubmit={(event) => {
                                 handleSubmit(event, city, overallTopic, topic, road, timeDimension, start, end,
                                     boxIndex, updateBoxInBoxes).then(() => {
                                         handleShow();
                                         updateStep(0);
                                     })}} disabled={disabled}/>
            </Modal>
        </div>
    );
};

export default ApiPicker;