import React from "react";
import {Button, ButtonGroup, Modal} from "react-bootstrap";

function MapLayer({showLayerChoice, roadScoreChecked, nodeChecked, roadSegmentsChecked, heatmapsChecked, busChecked,
                      busStopChecked, bikeChecked, carChecked, accidentChecked, perpetratorChecked, concernsChecked,
                      reportChecked, roadGeometryChecked, overlayEventSwitch, updateShowLayerChoice, updateLoadingSpin}) {

    const buttons = [
        {label: "1: Straßenscore", checked: roadScoreChecked},
        {label: "2: Knotenwerte", checked: nodeChecked},
        {label: "3: Straßeneinfärbungen", checked: roadSegmentsChecked},
        {label: "4: Heatmaps", checked: heatmapsChecked},
        {label: "5: Buslinien", checked: busChecked},
        {label: "6: Bushaltestellen", checked: busStopChecked},
        {label: "7: Rad Zählstellen", checked: bikeChecked},
        {label: "8: Auto Zählstellen", checked: carChecked},
        {label: "9: Unfallschwere", checked: accidentChecked},
        {label: "10: Unfallverursacher", checked: perpetratorChecked},
        {label: "11: Meldungen", checked: concernsChecked},
        {label: "12: RDD(Schadensmeldungen)", checked: reportChecked},
        {label: "13: Radweg Eigenschaften", checked: roadGeometryChecked},
        {label: "", checked: false}
    ];

    return (
        <Modal show={showLayerChoice} size={'lg'} scrollable animation autoFocus>
            <Modal.Header className='row'>
                <Modal.Title>Layer über Buttonklick ein-/ausblenden</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <br></br>
                <ButtonGroup className="layerbuttonrow">
                    {buttons.map(button => (
                        <Button onClick={() => {overlayEventSwitch(button.label);
                            if (!button.checked && button.label !== "") {
                                updateLoadingSpin(true);
                            }}}
                            className={`layerbutton ${button.checked ? "activebtn" : ""}`}>
                            {button.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => updateShowLayerChoice(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MapLayer;