import React from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {
    bikerIcon,
    busIcon,
    carAccidentIcon,
    eBikeIcon,
    peopleIcon,
    questionIcon,
    truckIcon
} from "../../functions/Icons";

function AccidentPerpetratorLayer({perpetratorChecked, groupedAccData, loadingSpin, filterCondition, moneyDamageFrom,
                                      moneyDamageTo, perpChoice, roadCond, accType}) {
    return (
        <LayersControl.Overlay name="10: Unfallverursacher" checked={perpetratorChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(groupedAccData).map(year =>
                    Object.keys(groupedAccData[year]).map(month =>
                        Object.keys(groupedAccData[year][month]).map(primaryKey => {
                            const data = groupedAccData[year][month][primaryKey];

                            let markerIcon;
                            if (data.perpetrator_german === "Auto") {
                                markerIcon = carAccidentIcon;
                            } else if (data.perpetrator_german === "LKW") {
                                markerIcon = truckIcon;
                            }
                            else if (data.perpetrator_german === "E-Bike" || data.perpetrator_german === "Motorrad") {
                                markerIcon = eBikeIcon;
                            }
                            else if (data.perpetrator_german === "Fußgänger") {
                                markerIcon = peopleIcon;
                            }
                            else if (data.perpetrator_german === "Fahrrad") {
                                markerIcon = bikerIcon;
                            }
                            else if (data.perpetrator_german === "Bus") {
                                markerIcon = busIcon;
                            }
                            else {
                                markerIcon = questionIcon;
                            }

                            let shouldDisplay = true;

                            filterCondition.forEach(condition => {
                                if (condition === "all") return;
                                switch (condition) {
                                    case "alcohol":
                                        shouldDisplay = shouldDisplay && data.cognos_alcohol === "true";
                                        break;
                                    case "drugs":
                                        shouldDisplay = shouldDisplay && data.cognos_drugs === "true";
                                        break;
                                    case "frost":
                                        shouldDisplay = shouldDisplay && data.temp < 0;
                                        break;
                                    case "money":
                                        shouldDisplay = shouldDisplay && data.cognos_monetary_damage >= moneyDamageFrom && data.cognos_monetary_damage <= moneyDamageTo;
                                        break;
                                    case "verletzte":
                                        shouldDisplay = shouldDisplay && data.injured == injured;
                                        break;
                                    case "verursacher":
                                        shouldDisplay = shouldDisplay && perpChoice.includes(data.perpetrator_german);
                                        break;
                                    case "oberflaeche":
                                        shouldDisplay = shouldDisplay && roadCond.includes(data.euska_road_condition);
                                        break;
                                    case "unfalltyp":
                                        shouldDisplay = shouldDisplay && accType.includes(data.euska_accident_type_long);
                                        break;
                                    default:
                                        return;
                                }
                            });

                            if (shouldDisplay) {
                                return (
                                    <Marker key={primaryKey} icon={markerIcon}
                                            position={[groupedAccData[year][month][primaryKey].euska_latitude,
                                                groupedAccData[year][month][primaryKey].euska_longitude]}>
                                        <Popup>
                                            <strong>Tag:</strong> {data.day}.{data.month}.{data.year}
                                            <strong> Uhrzeit:</strong> {data.hours}:{data.minutes} Uhr <br/>
                                            <strong>Unfalltyp Euska: </strong> {data.euska_accident_type_long}<br/>
                                            <strong> Cognos: </strong>{data.cognos_accident_type} <br/>
                                            <br/>
                                            <strong>Verursacher:</strong> {data.perpetrator_german} <br/>
                                            <strong>Beteiligte:</strong> {data.number_participants} Person
                                            mit {data.euska_participant ? data.euska_participant : "k.A."} <br/>
                                            <strong>Verletzte:</strong> {data.injured}
                                            <strong> (Tot/Schwer/Leicht):</strong> {data.killed}/{data.serious_injured}/{data.slightly_injured}
                                            <br/><br/>
                                            <strong>Schadenshöhe in €:</strong> {data.cognos_monetary_damage} <br/>
                                            <strong>Einfluss
                                                von</strong> Alkohol:{data.cognos_alcohol === "true" ? " Ja" : " - "} /
                                            Drogen: {data.cognos_drugs === "true" ? " Ja" : " - "} <br/><br/>
                                            <strong>Wetter:</strong> <br/>
                                            <strong>Temperatur:</strong> {data.temp} Grad
                                            / <strong>Hitzeindex:</strong>{data.heatindex ? data.heatindex : " k.A."}
                                            <br/>
                                            <strong>Oberfläche: </strong>{data.euska_road_condition} <br/>
                                            <strong>Bewölkt: </strong>{data.cloudcover ? data.cloudcover + "%" : " k.A."}<br/>
                                            <strong>Schnee: </strong>{data.snow ? data.snow : " k.A."},
                                            Tiefe: {data.snowdepth ? data.snowdepth : " k.A."} <br/>
                                        </Popup>
                                    </Marker>
                                );
                            }
                        })
                    )
                )}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default AccidentPerpetratorLayer;