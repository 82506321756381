import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {roadColorConditionsOptions, roadColorConditionsTranslations} from "../../functions/FilterFunctions";

function FilterHeatmaps({roadState, updateRoadState, updateLoadingSpin}) {

    return (
        <div>
            {roadColorConditionsOptions.map((roadType, index) => (
                <button key={index} className={`col-12 filterBtn ${roadState === roadType ? "selected" : ""}`}
                        onClick={() => { updateRoadState(roadType); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container">
                                <img src={roadState === roadType ? checked : unchecked}
                                     alt={roadState === roadType ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {roadColorConditionsTranslations[roadType]}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterHeatmaps;