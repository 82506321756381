import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell,Rectangle } from "recharts";
import {sortByKey, sortByKeyAsc} from "../functions/CommonFunctions";
import CustomTooltip from './CustomTooltip';
import { grey, lightGreen } from "@mui/material/colors";

function Bar_Chart({data, dataX, dataY, colors}) {
  data = Array.from(data);
  data = sortByKeyAsc(data, dataY);
  let abstand = 10;

  if (dataX=="topic_text") {
    abstand = 85;
  } else {
    abstand = 10;
  }
  
  const dataXValues = data.map(item => parseInt(item[dataY]));
 const maxValue = Math.max(...dataXValues);


  return (
    <ResponsiveContainer width='99%' height='88%'>
      <BarChart data={data} layout={"vertical"} margin={{ top: 5, right: 5, left: abstand, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis type="number" domain={[0, maxValue]} allowDataOverflow/>
        <YAxis fontSize={12} type="category" scaleToFit="true" textAnchor="end" textWrap={true} verticalAnchor="start" interval={0}
               angle="0" dataKey={dataX}/>
        <Tooltip content={<CustomTooltip />} />
        {/*<Legend/>*/}
        {dataY.map((dataKey, index) => (
          <Bar key={dataKey} dataKey={dataKey} activeBar={<Rectangle stroke="rgba(255, 255, 255, 0.3)" strokeWidth={4} />}>
            {data.map((entry, entryIndex) => (
              // if with colorarray <Cell key={`cell-${entryIndex}`} fill={colors[entryIndex % colors.length]} />
              <Cell key={`cell-${entryIndex}`} fill={"#6c9c34"} />
            ))}
          </Bar>
        ))}
          
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Bar_Chart;