import {errorHandling} from "../functions/Errors";
import {
    concernsDuration, concernsTime,
    concernsType, concernsTypeProblem
} from "../functions/ApiFunctions";
import {ApiConnect} from "../Dashboard";

export async function getConcerns(city, start, end, addBoxes) {
    Promise.all([
        ApiConnect.fetchConcernsTime(city, "month", [start, end]),
        ApiConnect.fetchConcernsType(city, [start, end]),
        ApiConnect.fetchConcernsDuration(city, [start, end]),
        ApiConnect.fetchConcernsTypeProblem(city, [start, end])
    ])
        .then(([data1, data2, data3, data4]) => {
            const box1 = concernsTime(data1, city, start, end, "month", "concerns-time", "concerns");
            const box2 = concernsType(data2, city, start, end, "year", "concerns-type", "concerns");
            const box3 = concernsDuration(data3, city, start, end, "year", "concerns-duration", "concerns");
            const box4 = concernsTypeProblem(data4, city, start, end, "year", "concerns-type-problem", "concerns");

            addBoxes([box1, box2, box3, box4])
        }).catch((error) => {
        errorHandling(error);
    });
}