import React, {useEffect, useState} from "react";
import '../../../styles/Modal.css';
import '../../../styles/Canvas.css';
import {Modal} from "react-bootstrap";
import StepCategory from "./modal/StepCategory";
import StepCity from "./modal/StepCity";
import StepTimeSpan from "./modal/StepTimeSpan";
import ModalHeader from "./modal/ModalHeader";
import ModalFooter from "./modal/ModalFooter";
import {handleTemplateSubmit} from "../functions/SubmitHandler";
import dayjs from "dayjs";
import {loadStations} from "../functions/ApiFunctions";

const CanvasInput = ({show, handleShow, addBoxes}) => {
    const [city, setCity] = useState("");
    const [overallTopic, setOverallTopic] = useState("");
    const [start, setStart] = useState(dayjs('2000-01-01'));
    const [end, setEnd] = useState(dayjs('2000-01-01'));

    const updateCity = (city) => { setCity(city); }
    const updateOverallTopic = (overallTopic) => { setOverallTopic(overallTopic); }
    const updateStart = (newValue) => { setStart(newValue); }
    const updateEnd = (newValue) => { setEnd(newValue); }

    const [roads, setRoads] = useState([]);

    const updateRoads = (roads) => { setRoads(roads); }

    useEffect(() => {
        loadStations(city, updateRoads);
    }, [city]);

    const [activeStep, setActiveStep] = useState(0);

    const updateStep = (step) => { setActiveStep(step); }

    const nextStep = () => { updateStep(activeStep !== steps.length-1 ? activeStep + 1 : activeStep); }

    const previousStep = () => { updateStep(activeStep !== 0 ? activeStep - 1 : 0); }

    const resetInput = () => {
        updateCity("");
        updateOverallTopic("");
        updateStart(dayjs('2000-01-01'));
        updateEnd(dayjs('2000-01-01'));
        updateStep(0);
    }

    const cancel = () => {
        resetInput();
        handleShow();
    }

    const steps = [
        <StepCategory overallTopic={overallTopic} topic={""} road={""} updateOverallTopic={updateOverallTopic}
                      updateTopic={() => {}} updateRoad={() => {}} nextStep={nextStep} roads={[]} all={true}/>,
        <StepCity city={city} updateCity={updateCity} nextStep={nextStep}/>,
        <StepTimeSpan start={start} updateStart={updateStart} end={end} updateEnd={updateEnd}
                      timeDimension={"all"} nextStep={nextStep}/>,
    ];

    const headers = [
        overallTopic !== "",
        city !== "",
        start.year() && end.year() !== dayjs('2000-01-01').year(),
    ];

    const disabled = city === "" || overallTopic === ""  || start.year() === dayjs('2000-01-01').year() ||
        end.year() === dayjs('2000-01-01').year();

    return (
        <div className="popup container-fluid adjust">
            <Modal show={show} size={'lg'} centered scrollable>
                <ModalHeader headers={headers} activeStep={activeStep} updateStep={updateStep}/>

                <Modal.Body className="modalStyle">
                    {steps.map((step, index) => (activeStep === index && ( step )))}
                </Modal.Body>

                <ModalFooter previousStep={previousStep} nextStep={nextStep} cancel={cancel} resetInput={resetInput}
                             handleSubmit={(event) => {
                                 handleTemplateSubmit(event, city, overallTopic, start, end, roads, addBoxes).then(() => {
                                     cancel();
                                 })}} disabled={disabled}/>
            </Modal>
        </div>
    );
};

export default CanvasInput;