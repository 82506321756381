import React from "react";
import DetailGraphBlock from "./DetailGraphBlock";
import '../../../styles/Modal.css';
import {Modal, Button, ModalBody} from "react-bootstrap";
import dayjs from "dayjs";
import {getCitiesShort} from "../functions/Translations";
import {updateLocale} from "dayjs";


dayjs.locale('de');

const Box = ({type, data, nameKey, dataKey, title, city, timeDimension, start, end, boxNr, isFullscreen,
                 toggleFullscreen}) => {
    const cities = getCitiesShort();

      return (
        <span>
            <div className='box' id={boxNr.toString()}>
                <br/>
                {title !== "" && <b>{title} ({cities[city]}) <br/>
                    {dayjs(start).format("DD. MMM YYYY")} - {dayjs(end).format("DD. MMM YYYY")}
                    {(timeDimension === "hour" || timeDimension === "quarter") ? ", " + dayjs(start).format("HH:mm")
                        + " - " + dayjs(end).format("HH:mm") + " Uhr" : ""}
                </b>}
                <DetailGraphBlock type={type} data={data} nameKey={nameKey} dataKey={dataKey} title={title} open={open}
                                  boxNr={boxNr}/>
            </div>

            <Modal show={isFullscreen} onHide={toggleFullscreen} centered fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">{title}</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <DetailGraphBlock type={type === "bar-stacked" ? "bar-stacked-zoom" : type} data={data}
                                      nameKey={nameKey} dataKey={dataKey} title={title} boxNr={boxNr}/>
                </ModalBody>
                <Modal.Footer>
                    <Button className="col btn" onClick={toggleFullscreen}>
                        Vollbild schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
};

export default Box;