export function emptyCheck() {
    if (document.getElementById("emptycheck") == null) {
        console.log("Fehler");
    } else {
        let element = document.getElementById("emptycheck");
        const text = element.textContent.trim();

        if (text === "Angabe fehlt") {
            element.style.color = "red";
        } else {
            element.style.color = "#6c9c34";
        }
    }
}

export function getOverallTopicForTopic(topic, overallTopics) {
    const filtered = Object.keys(overallTopics).filter((value) => topic.includes(value));

    if (filtered.length !== 1) {
        return "";
    } else {
        return filtered[0];
    }
}

export function getTimeSpansForTimeDimension(timeDimension, timeSpans) {
    let filter = [];

    switch (timeDimension) {
        case "year":
            filter = ["lastYear", "last3Years", "last5Years"];
            break;
        case "month":
            filter = ["lastMonth", "lastQuarter", "lastHalfYear"];
            break;
        case "dow":
        case "day":
            filter = ["lastWeek", "last14Days", "last30Days"];
            break;
        default:
            filter = ["lastYear", "lastMonth", "lastWeek"];
    }

    return Object.keys(timeSpans).filter((value) => filter.includes(value));
}