import React, { useEffect, useState } from "react";
import { RadarChart, Radar, PolarGrid, PolarRadiusAxis, PolarAngleAxis, ResponsiveContainer, Tooltip } from "recharts";
import { Modal, Button, ModalBody, ModalFooter } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import ApiConnection from "../api/ApiConnection";
import GradientScale from './GradientScale';
import Gut from '../../../images/angles-up-solid.svg';
import Mittel from '../../../images/angles-right-solid.svg';
import Schlecht from '../../../images/angles-down-solid.svg';
import logo from '../../../images/logo.png';


const RoadScore = () => {
  const ApiConnect = new ApiConnection(); // Eine Instanz der ApiConnection-Klasse erstellen

  const [roadNames, setRoadNames] = useState({});
  const [street, setStreet] = useState("Pferdemarkt");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("Oldenburg");
  const [city2, setCity2] = useState("");
  const [score, setScore] = useState(0);
  const [score2, setScore2] = useState(0);
  const [percscore, setPercScore] = useState(0);
  const [percscore2, setPercScore2] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestions2, setSuggestions2] = useState([]);
  const [data, setData] = useState([]);


  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const subjectTranslations = {
    "full_percentile_accidents_per_m": "Unfälle per m",
    "full_percentile_concerns_per_m": "Meldungen per m",
    "full_percentile_quality_total": "Qualität",
    "full_percentile_damages_per_m": "Schäden",
    "full_percentile_v_score_v2": "Erschütterung",
    "full_percentile_anomaly": "Anomalie",
    "full_percentile_time_loss": "Zeitverlust",
    "full_percentile_daily_traffic": "Verkehrsaufkommen"
  };

  //Straßennamen mit Stadtzuordnung laden
  useEffect(() => {
    ApiConnect.fetchRoadNames()
      .then((data) => {
        const newData = {};
        data.forEach((item) => {
          const { road_name, city } = item;
          const fullName = `${road_name} (${city})`;
          if (!newData[fullName]) {
            newData[fullName] = { city, road_names: [] };
          }
          newData[fullName].road_names.push(fullName);
        });
        setRoadNames(newData);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //fetch Funnktionsaufruf Straßenscore
  const fetchRoadScore = (street, city, street2, city2) => {

    // Überprüfe, ob street bestimmt und street2 nicht leer sind
    if (street && !street2) {
      Promise.all([
        ApiConnect.fetchRoadScoreStreet(street, city)
      ])
        .then(([dataRoad]) => {
          continueStreetScore(dataRoad);
          setLoadingSpin(false);
          setOpen(false);

        })
        .catch((error) => {
          console.error(error);
          setLoadingSpin(false);
          setOpen(false);
        });
    }


    // beide values bestimmt
    else if (street && street2) {
      Promise.all([
        ApiConnect.fetchRoadScoreStreet(street, city),
        ApiConnect.fetchRoadScoreStreet(street2, city2)
      ])
        .then(([dataRoad, dataRoad2]) => {
          console.log("Geladen");
          console.log(dataRoad);
          continueStreetScore2(dataRoad, dataRoad2);
          setLoadingSpin(false);
          setOpen(false);
        }
        )
        .catch((error) => {
          console.error(error);
          setLoadingSpin(false);
          setOpen(false);
        });
    }

  };

  //initialer Aufruf Pferdemarkt
  useEffect(() => {
    fetchRoadScore("Pferdemarkt", "Oldenburg", "", "");
  }, []);

  const continueStreetScore = (dataRoad) => {
    // Extrahiere die Spalten aus der ersten Antwort (unter der Annahme, dass alle Einträge dieselben Spalten haben)
    const columns = Object.keys(dataRoad[0]);

    // Filtere die 'road_name'-Spalte aus dem Spalten-Array heraus
    const subjects = columns.filter((column) => column !== 'road_name');

    // Initialisiere Objekte zur Berechnung der Summe und Anzahl der Werte für jeden Durchschnitt
    const columnSumsRoad = {};
    const columnCountsRoad = {};
    const nrSubjects = subjects.length - 1; //full_percentile_score ist die -1
    let scoreMath = 0;

    // Iteriere durch die Daten der ersten Straße (street)
    dataRoad.forEach((rowData) => {
      // Iteriere durch die Spalten (außer 'road_name')
      subjects.forEach((subject) => {
        const value = rowData[subject];

        // Initialisiere die Summe und die Anzahl für die Spalte, falls sie nicht existiert
        if (!columnSumsRoad[subject]) {
          columnSumsRoad[subject] = 0;
          columnCountsRoad[subject] = 0;
        }

        // Addiere den Wert zur Summe hinzu und erhöhe die Anzahl
        columnSumsRoad[subject] += value;
        columnCountsRoad[subject]++;
      });
    });

    // Berechne den Durchschnitt (Mittelwert) für jede Spalte für die erste Straße (street)
    const averagedDataRoad = {};

    subjects.forEach((subject, index) => {
      averagedDataRoad[subject] = (columnSumsRoad[subject] / columnCountsRoad[subject]).toFixed(2);
      scoreMath += (columnSumsRoad[subject] / columnCountsRoad[subject]);

      if (index === subjects.length - 1) {
        scoreMath /= nrSubjects;
      }
    });

    // Transformiere die durchschnittlichen Werte in das für das Radar-Diagramm erwartete Format
    const transformedData = subjects
      .filter((subject) => subject !== 'full_percentile_score') // Überspringe 'full_percentile_score'
      .map((subject) => ({
        subject: subjectTranslations[subject],
        A: averagedDataRoad[subject], // Durchschnittswert für street
        fullMark: 1, // Setze volle Markierung auf 1
      }));

    // Setze die Werte für score und score2
    setPercScore(parseFloat(averagedDataRoad['full_percentile_score']));
    setScore(scoreMath.toFixed(2));
    console.log("Fertig");
    console.log(data);
    // Aktualisiere den Zustand für die Daten des Radar-Diagramms
    setData(transformedData);
  }




  const continueStreetScore2 = (dataRoad, dataRoad2) => {

    // Extrahiere die Spalten aus der ersten Antwort (unter der Annahme, dass alle Einträge dieselben Spalten haben)
    const columns = Object.keys(dataRoad[0]);

    // Filtere die 'road_name'-Spalte aus dem Spalten-Array heraus
    const subjects = columns.filter((column) => column !== 'road_name');

    {/*....................................................................................................................*/ }

    // Initialisiere Objekte zur Berechnung der Summe und Anzahl der Werte für jeden Durchschnitt
    const columnSumsRoad = {};
    const columnCountsRoad = {};

    //wenn Qualität und Schäden mit einbezogen werden sollen
    const nrSubjects = subjects.length - 1; //full_percentile_score ist die -1
    let scoreMath = 0;


    // Iteriere durch die Daten der ersten Straße (street)
    dataRoad.forEach((rowData) => {
      // Iteriere durch die Spalten (außer 'road_name')
      subjects.forEach((subject) => {
        const value = rowData[subject];

        // Initialisiere die Summe und die Anzahl für die Spalte, falls sie nicht existiert
        if (!columnSumsRoad[subject]) {
          columnSumsRoad[subject] = 0;
          columnCountsRoad[subject] = 0;
        }

        // Addiere den Wert zur Summe hinzu und erhöhe die Anzahl
        columnSumsRoad[subject] += value;
        columnCountsRoad[subject]++;
      });
    });

    {/*....................................................................................................................*/ }


    // Initialisiere Objekte zur Berechnung der Summe und Anzahl der Werte für jeden Durchschnitt für die zweite Straße (street2)
    const columnSumsRoad2 = {};
    const columnCountsRoad2 = {};
    const nrSubjects2 = subjects.length - 1; //full_percentile_score ist die -1
    let scoreMath2 = 0;

    // Iteriere durch die Daten der zweiten Straße (street2)
    dataRoad2.forEach((rowData) => {
      const roadName = rowData.road_name;

      // Iteriere durch die Spalten (außer 'road_name')
      subjects.forEach((subject) => {
        const value = rowData[subject];

        // Initialisiere die Summe und die Anzahl für die Spalte, falls sie nicht existiert
        if (!columnSumsRoad2[subject]) {
          columnSumsRoad2[subject] = 0;
          columnCountsRoad2[subject] = 0;
        }

        // Addiere den Wert zur Summe hinzu und erhöhe die Anzahl
        columnSumsRoad2[subject] += value;
        columnCountsRoad2[subject]++;
      });
    });

    {/*....................................................................................................................*/ }

    // Berechne den Durchschnitt (Mittelwert) für jede Spalte für die erste Straße (street)
    const averagedDataRoad = {};
    subjects.forEach((subject, index) => {
      averagedDataRoad[subject] = (columnSumsRoad[subject] / columnCountsRoad[subject]).toFixed(2);
      scoreMath += (columnSumsRoad[subject] / columnCountsRoad[subject]);

      if (index === subjects.length - 1) {
        scoreMath /= nrSubjects;
      }
    })

    // Berechne den Durchschnitt (Mittelwert) für jede Spalte für die zweite Straße (street2)
    const averagedDataRoad2 = {};
    subjects.forEach((subject, index) => {
      averagedDataRoad2[subject] = (columnSumsRoad2[subject] / columnCountsRoad2[subject]).toFixed(2);
      scoreMath2 += (columnSumsRoad2[subject] / columnCountsRoad2[subject]);

      if (index === subjects.length - 1) {
        scoreMath2 /= nrSubjects2;
      }
    });

    {/*....................................................................................................................*/ }

    // Transformiere die durchschnittlichen Werte in das für das Radar-Diagramm erwartete Format
    const transformedData = subjects
      .filter((subject) => subject !== 'full_percentile_score') // Überspringe 'full_percentile_score'
      .map((subject) => ({
        subject: subjectTranslations[subject],
        A: averagedDataRoad[subject], // Durchschnittswert für street
        B: averagedDataRoad2[subject], // Durchschnittswert für street2
        fullMark: 1, // Setze volle Markierung auf 1
      }));
    setData(transformedData);
    // Setze die Werte für score und score2
    setScore(scoreMath.toFixed(2));
    setPercScore(parseFloat(averagedDataRoad['full_percentile_score']));
    setScore2(scoreMath2.toFixed(2));
    setPercScore2(parseFloat(averagedDataRoad2['full_percentile_score']));
  }

  //mache intelligente Suchvorschläge nach 3 Zeichen
  //für Straße 1
  useEffect(() => {
    if (searchTerm.length >= 3) {
      const results = Object.keys(roadNames).filter((name) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSuggestions(results);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm, roadNames]);

  //für Straße 2
  useEffect(() => {
    if (searchTerm2.length >= 3) {
      const results = Object.keys(roadNames).filter((name) =>
        name.toLowerCase().includes(searchTerm2.toLowerCase())
      );
      setSuggestions2(results);
    } else {
      setSuggestions2([]);
    }
  }, [searchTerm2, roadNames]);


  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleInputChange2 = (event) => {
    setSearchTerm2(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    const cityMatch = suggestion.match(/\((.*?)\)/);
    if (cityMatch) {
      const cityName = cityMatch[1];
      setCity(cityName);
    }
    setSearchTerm(suggestion);
    setSuggestions([]);
  };

  const handleSuggestionClick2 = (suggestion) => {
    const cityMatch = suggestion.match(/\((.*?)\)/);
    if (cityMatch) {
      const cityName = cityMatch[1];
      setCity2(cityName);
    }
    setSearchTerm2(suggestion);
    setSuggestions2([]);
  };

  //Eingabe löschen
  const clearInput1 = () => {
    setSearchTerm('');
    setScore(0);
  };

  const clearInput2 = () => {
    setSearchTerm2('');
    setScore2(0);
  };


  // Funktion, um das entsprechende Icon basierend auf dem Score zurückzugeben
  function getScoreIcon(score) {
    if (score >= 0 && score <= 0.35) {
      return Gut;
    } else if (score > 0.35 && score <= 0.70) {
      return Mittel;
    } else {
      return Schlecht;
    }
  }

  function getColorClass(score) {
    if (score >= 0 && score <= 0.35) {
      return "green"; // or any other classname for green color
    } else if (score > 0.35 && score <= 0.70) {
      return "yellow"; // or any other classname for yellow color
    } else {
      return "red"; // or any other classname for red color
    }
  }

  return (
    <>
      <ResponsiveContainer width='99%' height='70%' >
        <RadarChart outerRadius="70%" data={data} >
          <PolarGrid gridType="polygon" />
          <PolarAngleAxis dataKey="subject" offset={5} />
          <PolarRadiusAxis className="radarlabel" label={String} angle={70} domain={[0, 1]} /> {/* Update the domain to [0, 1] */}
          <Radar
            name={street}
            dataKey="A"
            stroke="#6c9c34"
            fill="#6c9c34"
            fillOpacity={0.1}
            isAnimationActive={true}
            dot
          />
          {!street2 ? "" :
            <Radar
              name={street2}
              dataKey="B"
              stroke="grey"
              fill="grey"
              fillOpacity={0.1}
              isAnimationActive={true}
              dot
            />
          }

          <Tooltip contentStyle={{ borderLeft: "4px solid #6c9c34", textAlign: "left", fontSize: "18px" }} ></Tooltip>
          {/* <Legend align="left" iconType="circle" wrapperStyle={{ marginLeft: 5 }} />*/}

        </RadarChart>
      </ResponsiveContainer>
      <div className="row scale"><p className="scale-label col">Schlecht</p><p className="scale-label col">1</p>   <GradientScale className="col" /><p className="scale-label col">0</p><p className="scale-label col">Gut</p> </div>
      <div className="row flex">
        <p className="col road1 flexscore">{street ? street : "keine Auswahl "}{city ? " (" + city.substring(0, 3) + ")" : ""}
          {": "} <span className={getColorClass(percscore)}>{percscore}</span> {" "}<img className="col-1 scoreicon desktop" src={getScoreIcon(percscore)} alt="Score Icon" /></p>

        <p className="col road2 flexscore">{street2 ? street2 : "keine Auswahl "}{city2 ? " (" + city2.substring(0, 3) + ")" : ""}
          {": "}
          <span className={getColorClass(percscore2)}>{percscore2}</span>
          {" "}<img className="col-1 scoreicon desktop" src={getScoreIcon(percscore2)} alt="Score Icon" /></p>
      </div>


      <Button className="row flex radarBtn" onClick={() => { setOpen(true) }} onMouseDown={(e) => e.stopPropagation()}>Straße auswählen</Button>

      <Modal show={open} onHide={onCloseModal} centered size="lg">
        <Modal.Header closeButton closeVariant="close">
          <Modal.Title id="contained-modal-title-lg">Wähle Deine Straße</Modal.Title>

        </Modal.Header>
        <ModalBody className="">
          {loadingSpin && (
            <div className="sweet-loading overlay-modal">
              <img src={logo} alt="Logo" className="logo" />
              <p className="loading-text">Straßenscore wird berechnet</p><br />
              <ClipLoader
                color={"#6c9c34"}
                loading={loadingSpin}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}




          {!loadingSpin && (
            <div className="roadchoice ">

              <div className="row" >
                <p className="streetname">Straße 1: </p>
              </div>
              <div className="row" >
                <input className="col-10 streetinput"
                  type="text"
                  placeholder="Wähle eine Straße"
                  value={searchTerm}
                  onChange={handleInputChange}
                  style={{ outline: "none" }}
                />
                {searchTerm && (
                  <button className="col-2 remove-button" onClick={clearInput1}>X</button>
                )}
              </div>
              {suggestions.length >= 1 && searchTerm.length >= 3 && (
                <ul>
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="streetsuggestions"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
              <hr></hr>
              <div className="row" >
                <p className="streetname">Straße 2: </p>
              </div>
              <div className="row">
                <input
                  className="col-10 streetinput"
                  type="text"
                  placeholder="Wähle eine Vergleichsstraße (optional)"
                  value={searchTerm2}
                  onChange={handleInputChange2}
                  style={{ outline: "none" }}
                />
                {searchTerm2 && (
                  <button className="col-2 remove-button"
                    onClick={clearInput2}>X</button>
                )}
              </div>
              {suggestions2.length >= 1 && searchTerm2.length >= 3 && (
                <ul>
                  {suggestions2.map((suggestion) => (
                    <li
                      key={suggestion}
                      onClick={() => handleSuggestionClick2(suggestion)}
                      className="streetsuggestions"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}

            </div>
          )}
        </ModalBody>
        <ModalFooter className='einstiegfooter'>
          <Button className="col-8"
            onClick={() => {
              // Split the selected suggestions into road names and cities
              setLoadingSpin(true);
              let temp = searchTerm;
              let temp2 = searchTerm2;
              const [street, city] = temp.split(' (');
              let newcity = city ? city.slice(0, -1) : '';
              const [street2, city2] = temp2.split(' (');
              let newcity2 = city2 ? city2.slice(0, -1) : '';
              // Set the values in your state
              setStreet(street);
              setStreet2(street2);
              fetchRoadScore(street, newcity, street2, newcity2);
              //setOpen(false);
            }}
          >
            Übernehmen
          </Button>
        </ModalFooter>

      </Modal>


    </>
  );
}

export default RoadScore;