import {Button} from "react-bootstrap";
import React from "react";
import {getTimeDimensions} from "../../functions/Translations";
import {emptyCheck} from "../../functions/ApiPickerFunctions";

const StepTimeDimension = ({timeDimension, updateTimeDimension, nextStep}) => {
    const timeDimensions = getTimeDimensions();

    return (
        <div>
            <div className="row">
                <p className="auswahlTitel col-12">Wähle eine Zeitdimension:</p>
                <div className="buttonbox row col-12">
                    <div id="timeButton" className="buttonbox row">
                        <p id="emptycheck" className="col" style={{color: timeDimension !== "" ? "#6c9c34" : "red"}}>
                            {timeDimension !== "" ? timeDimensions[timeDimension] : "Angabe fehlt"}
                        </p>
                    </div>
                    <div id="timeButton buttonbox" className="row">
                        {Object.keys(timeDimensions).map((value) => (
                            <Button className="dropdown-api-picker-item col-2"
                                    onClick={() => { updateTimeDimension(value); emptyCheck(); nextStep(); }}>
                                {timeDimensions[value]}
                            </Button>))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepTimeDimension;