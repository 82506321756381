import React, { useState, useEffect, useRef } from "react";
import { TileLayer, MapContainer, LayersControl, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../../../styles/App.css';
import "react-datepicker/dist/react-datepicker.css";
import RoadScoreLayer from "../map/layers/RoadScoreLayer";
import NodesLayer from "../map/layers/NodesLayer";
import RoadsLayer from "../map/layers/RoadsLayer";
import HeatmapsLayer from "../map/layers/HeatmapsLayer";
import BusLinesLayer from "../map/layers/BusLinesLayer";
import BicycleCountingLayer from "../map/layers/BicycleCountingLayer";
import CarCountingLayer from "../map/layers/CarCountingLayer";
import AccidentSeverityLayer from "../map/layers/AccidentSeverityLayer";
import AccidentPerpetratorLayer from "../map/layers/AccidentPerpetratorLayer";
import ConcernsLayer from "../map/layers/ConcernsLayer";
import DamageReportsLayer from "../map/layers/DamageReportsLayer";
import BusStopsLayer from "../map/layers/BusStopsLayer";
import AccordionGeneral from "../map/filter/AccordionGeneral";
import AccordionDamageAmount from "../map/filter/AccordionDamageAmount";
import AccordionPerpetrator from "../map/filter/AccordionPerpetrator";
import AccordionSurface from "../map/filter/AccordionSurface";
import AccordionAccidentType from "../map/filter/AccordionAccidentType";
import FilterRoadScore from "../map/filter/FilterRoadScore";
import FilterNodeState from "../map/filter/FilterNodeState";
import FilterRoadSegmentScore from "../map/filter/FilterRoadSegmentScore";
import FilterHeatmaps from "../map/filter/FilterHeatmaps";
import AccordionWeather from "../map/filter/AccordionWeather";
import FilterConcerns from "../map/filter/FilterConcerns";
import StepTimeSpan from "../api/modal/StepTimeSpan";
import dayjs from "dayjs";
import MapFilter from "../map/modals/MapFilter";
import MapLayer from "../map/modals/MapLayer";
import CityModal from "../map/modals/CityModal";
import LoadingView from "../map/LoadingView";
import GeometryRoadLayer from "../map/layers/GeometryRoadLayer";
import FilterRoadGeometry from "../map/filter/FilterRoadGeometry";

const LocationStation = () => {
    const mapRef = useRef(null);
    const [center, setCenter] = useState([53.1435, 8.2146]);
    const [zoom, setZoom] = useState(14);
    const [mapKey, setMapKey] = useState(0);
    const [town, setTown] = useState("Oldenburg");

    const getInitialBounds = () => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                return map.getBounds();
            }
        }
    }

    let initialBounds = getInitialBounds();

    const [mapBounds, setMapBounds] = useState(initialBounds);
    const updateMapBounds = (bounds) => {
        setMapBounds(bounds);
    }

    const MapEvents = () => {
        useMapEvents({
            dragend: () => {
                runMapEvent();
            },
            zoomend: () => {
                runMapEvent();
            },
            resize: () => {
                runMapEvent();
            }
        });
        return null;
    };

    const runMapEvent = () => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                const bounds = map.getBounds();
                updateMapBounds(bounds);
                {/* After refactoring each layer should use bounding boxes:
                const checked = roadScoreChecked || nodeChecked || roadSegmentsChecked || heatmapsChecked || busChecked
                    || busStopChecked || bikeChecked || carChecked || accidentChecked || perpetratorChecked
                    || concernsChecked || reportChecked || roadGeometryChecked;*/}
                const checked = roadScoreChecked || nodeChecked || roadSegmentsChecked || heatmapsChecked || busChecked
                    || busStopChecked || accidentChecked || perpetratorChecked
                    || concernsChecked || reportChecked || roadGeometryChecked;
                if (checked) {
                    updateLoadingSpin(true);
                }
            }
        }
    }

    const [concernsChecked, setConcernsChecked] = useState(false);
    const updateConcernsChecked = (checked) => {
        setConcernsChecked(checked);
    }

    const [accidentChecked, setAccidentChecked] = useState(false);
    const updateAccidentChecked = (checked) => {
        setAccidentChecked(checked);
    }

    const [perpetratorChecked, setPerpetratorChecked] = useState(false);
    const updatePerpetratorChecked = (checked) => {
        setPerpetratorChecked(checked);
    }

    const [busChecked, setBusChecked] = useState(false);
    const updateBusChecked = (checked) => {
        setBusChecked(checked);
    }

    const [busStopChecked, setBusStopChecked] = useState(false);
    const updateBusStopChecked = (checked) => {
        setBusStopChecked(checked);
    }

    const [reportChecked, setReportChecked] = useState(false);
    const updateReportChecked = (checked) => {
        setReportChecked(checked);
    }

    const [carChecked, setCarChecked] = useState(false);
    const updateCarChecked = (checked) => {
        setCarChecked(checked);
    }

    const [bikeChecked, setBikeChecked] = useState(false);
    const updateBikeChecked = (checked) => {
        setBikeChecked(checked);
    }

    const [roadSegmentsChecked, setRoadSegmentsChecked] = useState(false);
    const updateRoadSegmentsChecked = (checked) => {
        setRoadSegmentsChecked(checked);
    }

    const [roadScoreChecked, setRoadScoreChecked] = useState(false);
    const updateRoadScoreChecked = (checked) => {
        setRoadScoreChecked(checked);
    }

    const [heatmapsChecked, setHeatmapsChecked] = useState(false);
    const updateHeatmapsChecked = (checked) => {
        setHeatmapsChecked(checked);
    }

    const [nodeChecked, setNodeChecked] = useState(false);
    const updateNodeChecked = (checked) => {
        setNodeChecked(checked);
    }

    const [roadGeometryChecked, setRoadGeometryChecked] = useState(false);
    const updateRoadGeometryChecked = (checked) => {
        setRoadGeometryChecked(checked);
    }

    const [roadGeometryState, setRoadGeometryState] = useState("Slope");
    const updateRoadGeometryState = (newState) => {
        setRoadGeometryState(newState)
    };

    const [roadSegmentState, setRoadSegmentState] = useState("AnomalieSeg");
    const updateRoadSegmentState = (newState) => {
        setRoadSegmentState(newState)
    };

    const [scoreState, setScoreState] = useState("Score");
    const updateScoreState = (newState) => {
        setScoreState(newState)
    };

    const [nodeState, setNodeState] = useState("V1");
    const updateNodeState = (newState) => {
        setNodeState(newState)
    };

    const [roadState, setRoadState] = useState("V1Road");
    const updateRoadState = (newState) => {
        setRoadState(newState)
    };

    const [heatmapData, setHeatmapData] = useState([]);
    const updateHeatmapData = (newData) => {
        setHeatmapData(newData);
    }

    const [roadScoreData, setRoadScoreData] = useState([]);
    const updateRoadScoreData = (newData) => {
        setRoadScoreData(newData);
    }

    const [roadsData, setRoadsData] = useState([]);
    const updateRoadsData = (newData) => {
        setRoadsData(newData);
    }

    const [nodesData, setNodesData] = useState([]);
    const updateNodesData = (newData) => {
        setNodesData(newData);
    }

    const [busData, setBusData] = useState([]);
    const updateBusData = (newData) => {
        setBusData(newData);
    }

    const [groupedData, setGroupedData] = useState({});
    const updateGroupedData = (newData) => {
        setGroupedData(newData);
    }

    const [busStops, setBusStops] = useState({});
    const updateBusStops = (newData) => {
        setBusStops(newData);
    }

    const [roadGeometry, setRoadGeometry] = useState({});
    const updateRoadGeometry = (newData) => {
        setRoadGeometry(newData);
    }

    const [groupedAccData, setGroupedAccData] = useState({});
    const updateGroupedAccData = (newData) => {
        setGroupedAccData(newData);
    }

    const [groupedConcernsData, setGroupedConcernsData] = useState({});
    const updateGroupedConcernsData = (newData) => {
        setGroupedConcernsData(newData);
    }

    const [groupedCarStations, setGroupedCarStations] = useState({});
    const updateGroupedCarStations = (newData) => {
        setGroupedCarStations(newData);
    }

    const [groupedCarStationsOl, setGroupedCarStationsOl] = useState({});
    const updateGroupedCarStationsOl = (newData) => {
        setGroupedCarStationsOl(newData);
    }

    const [reportedImg, setReportedImg] = useState({});
    const updateReportedImg = (newImg) => {
        setReportedImg(newImg);
    }

    const [filterCondition, setFilterCondition] = useState([]);
    const [filterConcerns, setFilterConcerns] = useState([]);
    const [concernActive, setConcernActive] = useState(["Alle"]);
    const [filterWeather, setFilterWeather] = useState([]);
    const [perpChoice, setPerpChoice] = useState([]);
    const [roadCond, setRoadCond] = useState([]);
    const [accType, setAccType] = useState([]);

    const [moneyDamageFrom, setMoneyDamageFrom] = useState(0);
    const updateMoneyDamageFrom = (newMoney) => {
        setMoneyDamageFrom(newMoney);
    }

    const [moneyDamageTo, setMoneyDamageTo] = useState(0);
    const updateMoneyDamageTo = (newMoney) => {
        setMoneyDamageTo(newMoney);
    }

    const [injured, setInjured] = useState(1);
    const updateInjured = (newInjured) => {
        setInjured(newInjured);
    }

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTownPicker, setShowTownPicker] = useState(false);
    const [showLayerChoice, setShowLayerChoice] = useState(false);
    const [loadingSpin, setLoadingSpin] = useState(false);

    const updateShowDatePicker = (show) => {
        setShowDatePicker(show);
    }

    const updateShowTownPicker = (show) => {
        setShowTownPicker(show);
    }

    const updateShowLayerChoice = (show) => {
        setShowLayerChoice(show);
    }

    const updateLoadingSpin = (show) => {
        setLoadingSpin(show);
    }

    const [startDate, setStartDate] = useState(dayjs("2021-01-01 00:00:00"));
    const [endDate, setEndDate] = useState(dayjs("2023-12-31 23:59:00"));

    const sD = startDate.format("YYYY-MM-DD HH:mm:ss");
    const eD = endDate.format("YYYY-MM-DD HH:mm:ss");

    const sDQuarter = startDate.format("HH:mm:ss");
    const eDQuarter = endDate.format("HH:mm:ss");

    const updateStartDate = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const updateEndDate = (date) => {
        if (date) {
            setEndDate(date);
        }
    };

    const handleSwitchChange = (choice) => {
        if (choice === "Osnabrück") {
            // Wechsel zu Osnabrück
            setTown("Osnabrueck");
            setCenter([52.2767, 8.0436]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }
        else if (choice === "Oldenburg") {
            // Wechsel zu Oldenburg
            setTown("Oldenburg");
            setCenter([53.1435, 8.2146]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }
        else if (choice === "Köln") {
            // Wechsel zu Köln
            setTown("Köln");
            setCenter([50.935173, 6.953101]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }

    };

    const overlayEventSwitch = (name) => {
        switch (name) {
            case "1: Straßenscore":
                updateRoadScoreChecked(!roadScoreChecked);
                break;
            case "2: Knotenwerte":
                updateNodeChecked(!nodeChecked);
                break;
            case "3: Straßeneinfärbungen":
                updateRoadSegmentsChecked(!roadSegmentsChecked);
                break;
            case "4: Heatmaps":
                updateHeatmapsChecked(!heatmapsChecked);
                break;
            case "5: Buslinien":
                updateBusChecked(!busChecked);
                break;
            case "6: Bushaltestellen":
                updateBusStopChecked(!busStopChecked);
                break;
            case "7: Rad Zählstellen":
                updateBikeChecked(!bikeChecked);
                break;
            case "8: Auto Zählstellen":
                updateCarChecked(!carChecked);
                break;
            case "9: Unfallschwere":
                updateAccidentChecked(!accidentChecked);
                break;
            case "10: Unfallverursacher":
                updatePerpetratorChecked(!perpetratorChecked);
                break;
            case "11: Meldungen":
                updateConcernsChecked(!concernsChecked);
                break;
            case "12: RDD(Schadensmeldungen)":
                updateReportChecked(!reportChecked);
                break;
            case "13: Radweg Eigenschaften":
                updateRoadGeometryChecked(!roadGeometryChecked);
                break;
            default:
                return;
        }
    }

    useEffect(() => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                map.invalidateSize();
            }
        }
    });

    const filterFunction = (value) => {
        if (filterCondition.includes(value)) {
            removeFilter(value);
        }

        if (!filterCondition.includes(value)) {
            setFilterCondition([...filterCondition, value]);
        }
    }

    const removeFilter = (value) => {
        if (filterCondition.includes(value)) {
            setFilterCondition(filterCondition.filter(item => item !== value));
        }

        switch (value) {
            case "oberflaeche":
                setRoadCond([]);
                break;
            case "verursacher":
                setPerpChoice([]);
                break;
            case "unfalltyp":
                setAccType([]);
                break;
            case "wetter":
                setFilterWeather([]);
                break;
            case "node":
                setNodeState("");
                break;
            case "concern":
                setConcernActive(["Alle"]);
                break;
            default:
                break;
        }
    }

    const filterFunctionExtra = (value, extra) => {
        // Überprüfen, welcher Wert übergeben wurde
        if (value === "verursacher") {
            // Spezielle Aktionen für "verursacher" Wert
            if (roadCond.length === 0) {
                // Wenn keine Straßenbedingungen ausgewählt sind, entferne "oberflaeche" aus den Filterbedingungen
                setFilterCondition(filterCondition.filter(item => item !== "oberflaeche"));
            }
            if (perpChoice.includes(extra)) {
                // Wenn der Zusatzwert bereits in der Verursacherauswahl enthalten ist, entferne ihn
                setPerpChoice(perpChoice.filter(item => item !== extra));
            } else {
                // Füge den Zusatzwert zur Verursacherauswahl hinzu
                setPerpChoice([...perpChoice, extra]);
            }
        }

        if (value === "oberflaeche") {
            if (perpChoice.length === 0) {
                setFilterCondition(filterCondition.filter(item => item !== "verursacher"));
                console.log("weg" + filterCondition)
            }
            if (roadCond.includes(extra)) {
                setRoadCond(roadCond.filter(item => item !== extra));
            } else {
                setRoadCond([...roadCond, extra]);
            }
        }

        if (value === "unfalltyp") {
            console.log(extra)
            if (accType.includes(extra)) {
                setAccType(accType.filter(item => item !== extra));
            } else {
                setAccType([...accType, extra]);
            }
        }

        if (value === "wetter") {
            if (filterWeather.includes(extra)) {
                setFilterWeather(filterWeather.filter(item => item !== extra));
            } else {
                setFilterWeather([...filterWeather, extra]);
            }
        }

        if (!filterCondition.includes(value)) {
            setFilterCondition([...filterCondition, value]);
        }
    };

    const filterSetConcerns = (values, keyword) => {
        let updatedConcernActive;
        if (concernActive.includes(keyword)) {
            updatedConcernActive = concernActive.filter((item) => item !== keyword);
        } else {
            updatedConcernActive = [...concernActive, keyword];
        }
        setConcernActive(updatedConcernActive);

        let updatedFilterConcerns = filterConcerns.slice(); // Create a copy of the array
        values.forEach((value) => {
            if (updatedFilterConcerns.includes(value)) {
                updatedFilterConcerns = updatedFilterConcerns.filter((item) => item !== value);
            } else {
                updatedFilterConcerns.push(value);
            }
        });
        setFilterConcerns(updatedFilterConcerns);
    };

    const [tab, setTab] = useState('1');
    const [tabLabel, setTabLabel] = useState('Zeitfilter');

    const updateTab = (newTab) => {
        setTab(newTab);
    };

    const updateTabLabel = (newTabLabel) => {
        setTabLabel(newTabLabel);
    };

    const timeDimension = bikeChecked ? "quarter" : "all";

    const tabs = [
        <div className="m-2">
            <StepTimeSpan timeDimension={timeDimension} start={startDate} end={endDate}
                updateStart={updateStartDate} updateEnd={updateEndDate} nextStep={() => { }} />
        </div>,
        <div className="m-4" onClick={() => setLoadingSpin(true)}>
            <FilterRoadScore scoreState={scoreState} updateScoreState={updateScoreState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterNodeState nodeState={nodeState} updateNodeState={updateNodeState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterRoadSegmentScore roadSegmentState={roadSegmentState} updateRoadSegmentState={updateRoadSegmentState}
                updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterHeatmaps roadState={roadState} updateRoadState={updateRoadState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <AccordionGeneral filterCondition={filterCondition} injured={injured} filterFunction={filterFunction} updateInjured={updateInjured} />
            <AccordionDamageAmount moneyDamageFrom={moneyDamageFrom} moneyDamageTo={moneyDamageTo} filterCondition={filterCondition}
                filterFunction={filterFunction} updateMoneyDamageFrom={updateMoneyDamageFrom} updateMoneyDamageTo={updateMoneyDamageTo} />
            <AccordionPerpetrator perpChoice={perpChoice} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionSurface roadCond={roadCond} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionAccidentType accType={accType} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionWeather filterWeather={filterWeather} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
        </div>,
        <div className="m-4">
            <FilterConcerns concernActive={concernActive} removeFilter={removeFilter} filterSetConcerns={filterSetConcerns} />
        </div>,
        <div className="m-4">
            <FilterRoadGeometry roadGeometryState={roadGeometryState} updateRoadGeometryState={updateRoadGeometryState}
                                updateLoadingSpin={updateLoadingSpin}/>
        </div>
    ];

    const buttons = [
        { label: "Daten wählen", function: () => setShowLayerChoice(true), bottom: '130px' },
        { label: "Filtern", function: () => setShowDatePicker(true), bottom: '80px' },
        { label: "Stadtwechsel", function: () => setShowTownPicker(true), bottom: '30px' }
    ];

    return (
        <div className="map-container">
            <MapContainer ref={mapRef} style={{ height: "91%", width: "99%" }} key={mapKey} center={center} zoom={zoom}
                scrollWheelZoom={false}>
                <TileLayer attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors"
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    subdomains={['a', 'b', 'c', 'd']} />

                <MapEvents />

                {buttons.map(button => (
                    <button onClick={button.function} className="changeBtn"
                        style={{
                            zIndex: 999, position: 'fixed', bottom: button.bottom, left: '20px', width: '30%',
                            minWidth: 'fit-content', padding: '10px', fontSize: 'medium'
                        }}>
                        {button.label}
                    </button>
                ))}
                <div className="maptext" onClick={() => setShowDatePicker(true)}>
                <div className="timeIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                        <style><svg>fill:grey</svg></style>
                        <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>
                    </div>
                    <strong>{dayjs(startDate).format("DD. MMM YYYY")}</strong> -
                    <strong>{dayjs(endDate).format("DD. MMM YYYY")}</strong>
                </div>

                <LoadingView loadingSpin={loadingSpin} />
                <MapFilter tab={tab} tabs={tabs} tabContents={tabs} tabLabel={tabLabel} roadScoreChecked={roadScoreChecked}
                           nodeChecked={nodeChecked} roadSegmentsChecked={roadSegmentsChecked} heatmapsChecked={heatmapsChecked}
                           accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked}
                           concernsChecked={concernsChecked} roadGeometryChecked={roadGeometryChecked}
                           showDatePicker={showDatePicker} updateShowDatePicker={updateShowDatePicker} updateTab={updateTab}
                           updateTabLabel={updateTabLabel} updateLoadingSpin={updateLoadingSpin}/>

                <CityModal showTownPicker={showTownPicker} town={town} updateShowTownPicker={updateShowTownPicker}
                    handleSwitchChange={handleSwitchChange} />

                <MapLayer showLayerChoice={showLayerChoice} roadScoreChecked={roadScoreChecked} nodeChecked={nodeChecked}
                    roadSegmentsChecked={roadSegmentsChecked} heatmapsChecked={heatmapsChecked} busChecked={busChecked}
                    busStopChecked={busStopChecked} bikeChecked={bikeChecked} carChecked={carChecked}
                    accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked} concernsChecked={concernsChecked}
                    reportChecked={reportChecked} roadGeometryChecked={roadGeometryChecked} overlayEventSwitch={overlayEventSwitch}
                    updateShowLayerChoice={updateShowLayerChoice} updateLoadingSpin={updateLoadingSpin} />

                <LayersControl position="bottomright">
                    <RoadScoreLayer scoreState={scoreState} roadScoreChecked={roadScoreChecked}
                                    roadScoreData={roadScoreData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                    updateRoadScoreData={updateRoadScoreData} updateLoadingSpin={updateLoadingSpin}/>

                    <NodesLayer nodeState={nodeState} nodeChecked={nodeChecked} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                nodesData={nodesData} updateNodesData={updateNodesData} updateLoadingSpin={updateLoadingSpin}/>

                    <RoadsLayer roadSegmentState={roadSegmentState} roadSegmentsChecked={roadSegmentsChecked}
                                roadsData={roadsData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                updateRoadsData={updateRoadsData} updateLoadingSpin={updateLoadingSpin}/>

                    <HeatmapsLayer roadState={roadState} heatmapsChecked={heatmapsChecked}
                                   heatmapData={heatmapData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                   updateHeatmapData={updateHeatmapData} updateLoadingSpin={updateLoadingSpin} />

                    <BusLinesLayer busChecked={busChecked} busData={busData} mapBounds={mapBounds}
                                   loadingSpin={loadingSpin} updateBusData={updateBusData}
                                   updateLoadingSpin={updateLoadingSpin} />

                    <BusStopsLayer busStopChecked={busStopChecked} busStops={busStops} mapBounds={mapBounds}
                                   loadingSpin={loadingSpin} updateBusStops={updateBusStops}
                                   updateLoadingSpin={updateLoadingSpin} />

                    <BicycleCountingLayer bikeChecked={bikeChecked} groupedData={groupedData} sD={sD} eD={eD}
                        sDQuarter={sDQuarter} eDQuarter={eDQuarter}
                        updateGroupedData={updateGroupedData} updateLoadingSpin={updateLoadingSpin} />

                    <CarCountingLayer carChecked={carChecked} groupedCarStations={groupedCarStations} sD={sD} eD={eD}
                        groupedCarStationsOl={groupedCarStationsOl} town={town} mapRef={mapRef}
                        mapBounds={mapBounds} updateMapBounds={updateMapBounds}
                        updateGroupedCarStations={updateGroupedCarStations}
                        updateGroupedCarStationsOl={updateGroupedCarStationsOl}
                        updateLoadingSpin={updateLoadingSpin} />

                    <AccidentSeverityLayer accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked}
                                           groupedAccData={groupedAccData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                           filterCondition={filterCondition} moneyDamageFrom={moneyDamageFrom}
                                           moneyDamageTo={moneyDamageTo} perpChoice={perpChoice} roadCond={roadCond} accType={accType}
                                           filterWeather={filterWeather} sD={sD} eD={eD} sDQuarter={sDQuarter} eDQuarter={eDQuarter}
                                           updateGroupedAccData={updateGroupedAccData} updateLoadingSpin={updateLoadingSpin} />

                    <AccidentPerpetratorLayer perpetratorChecked={perpetratorChecked} groupedAccData={groupedAccData}
                                              loadingSpin={loadingSpin} filterCondition={filterCondition}
                                              moneyDamageFrom={moneyDamageFrom} moneyDamageTo={moneyDamageTo}
                                              perpChoice={perpChoice} roadCond={roadCond} accType={accType} />

                    <ConcernsLayer concernsChecked={concernsChecked} groupedConcernsData={groupedConcernsData}
                                   concernActive={concernActive} filterConcerns={filterConcerns} sD={sD} eD={eD}
                                   mapBounds={mapBounds} updateGroupedConcernsData={updateGroupedConcernsData}
                                   updateLoadingSpin={updateLoadingSpin} />

                    <DamageReportsLayer reportChecked={reportChecked} reportedImg={reportedImg} startDate={startDate}
                                        endDate={endDate} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                        updateReportedImg={updateReportedImg} updateLoadingSpin={updateLoadingSpin} />

                    <GeometryRoadLayer roadGeometryState={roadGeometryState} roadGeometryChecked={roadGeometryChecked}
                                       roadGeometry={roadGeometry} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                       updateRoadGeometry={updateRoadGeometry} updateLoadingSpin={updateLoadingSpin}/>
                </LayersControl>
            </MapContainer>
        </div>
    );
}

export default LocationStation;