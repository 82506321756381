import React from "react";
import Pie_Chart from "../charts/Pie_Chart";
import Line_Chart from "../charts/Line_Chart";
import Bar_Chart from "../charts/Bar_Chart";
import Scatter_Chart from "../charts/Scatter_Chart";
import Radial_Chart from "../charts/Radial_Chart";
import Bar_Chart_Stacked from "../charts/Bar_Chart_Stacked";
import Bar_Chart_Stacked_normal from "../charts/Bar_Chart_Stacked_normal";
import Bar_Chart_StackedZoom from "../charts/Bar_Chart_StackedZoom";
import StackedArea_chart from "../charts/StackedArea_Chart";

const COLORS = [
    "hsl(88, 50%, 41%)", "hsl(184, 6%, 53%)", "hsl(204, 70%, 53%)", "hsl(48, 89%, 50%)",
    "hsl(28, 80%, 52%)", "hsl(6, 78%, 57%)", "hsl(283, 39%, 53%)", "hsl(5, 20%, 53%)",
    "hsl(88, 100%, 15%)", "hsl(184, 6%, 20%)", "hsl(204, 70%, 20%)", "hsl(48, 89%, 60%)",
    "hsl(28, 80%, 80%)", "hsl(6, 100%, 50%)", "hsl(204, 50%, 30%)", "hsl(150, 78%, 80%)"
];

const GraphBlock = ({type, data, nameKey, dataKey, isOpen, boxNr}) => {
    switch (type) {
        case "pie":
        case "pie_new_colors":
            return (
                <Pie_Chart data={data} dataX={nameKey} dataY={dataKey} colors={COLORS} type={type}/>
            );
        case "line":
            return (
                <Line_Chart data={data} dataX={nameKey} dataY={dataKey} colors={COLORS} isOpen={isOpen}/>
            );

        case "radial":
            return (
                <Radial_Chart data={data} dataX={nameKey} dataY={dataKey} colors={COLORS} new_text={new_text}/>
            );
        case "bar":
            return (
                <Bar_Chart data={Array.from(data)} dataX={nameKey} dataY={dataKey} colors={COLORS}/>
            );
        case "stacked-area":
            return (
                <StackedArea_chart data={data} dataX={nameKey} dataY={dataKey} colors={COLORS}/>
            );
        case "scatter":
            return (
                <Scatter_Chart data={data} dataX={nameKey} dataY={dataKey} colors={COLORS}/>
            );
        case "bar-stacked":
            return (
                <Bar_Chart_Stacked data={data} dataX={nameKey} dataY={dataKey} colors={COLORS} boxNr={boxNr}/>
            );
        case "bar-stacked-normal":
                return (
                    <Bar_Chart_Stacked_normal data={data} dataX={nameKey} dataY={dataKey} colors={COLORS} boxNr={boxNr}/>
                );
        case "bar-stacked-zoom":
            return (
                <Bar_Chart_StackedZoom data={data} dataX={nameKey} dataY={dataKey} colors={COLORS}
                                       boxnr={boxNr + "zoom"}/>
            );
        default:
            return null;
    }
};

export default GraphBlock;