import React from "react";
import {filterRoadGeometryOptions} from "../../functions/FilterFunctions";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";

function FilterRoadGeometry({roadGeometryState, updateRoadGeometryState, updateLoadingSpin}) {

    return (
        <div>
            {filterRoadGeometryOptions.map((roadGeometryType, index) => (
                <button key={index} className={`col-12 filterBtn ${roadGeometryState === roadGeometryType.keyword ? "selected" : ""}`}
                        onClick={() => { updateRoadGeometryState(roadGeometryType.keyword); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container">
                                <img src={roadGeometryState === roadGeometryType.keyword ? checked : unchecked}
                                     alt={roadGeometryState === roadGeometryType.keyword ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {roadGeometryType.value}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterRoadGeometry;