import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {fetchRoadsData} from "../../functions/MapFunctions";
import {getRoadStateProperties} from "../../functions/Translations";

function RoadsLayer({roadSegmentState, roadSegmentsChecked, roadsData, mapBounds, loadingSpin,
                        updateRoadsData, updateLoadingSpin}) {

    const roadStateProperties = getRoadStateProperties();

    const useRoadsData = useCallback(() => {
        const property = roadStateProperties[roadSegmentState];

        fetchRoadsData(property, mapBounds, updateRoadsData, updateLoadingSpin);
    }, [mapBounds, roadSegmentState]);

    useEffect(() => {
        if (roadSegmentsChecked) {
            useRoadsData();
        }
    }, [mapBounds, roadSegmentState, roadSegmentsChecked]);

    return (
        <LayersControl.Overlay name="3: Straßeneinfärbungen" checked={roadSegmentsChecked}>
            <LayerGroup>
                {/* Anomaly-RoadSegment-Score anzeigen */}
                {roadSegmentState === "AnomalieSeg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((i) => {

                    const road = roadsData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', "yellow", 'red']);

                    return (
                        <Polyline
                            positions={road.geometry.coordinates}
                            color={colormap(road.anomaly)}
                            weight={4}
                            fill={false}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Anomalien: " + ((road.anomaly === null) ? 0 : road.anomaly.toFixed(4)) + " | Gut [0;1] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* Time-Loss-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "TimeLossSeg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((i) => {

                    const road = roadsData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', 'yellow', 'red']);

                    return (
                        <Polyline
                            positions={road.geometry.coordinates}
                            color={colormap(road.time_loss / 30)}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Zeitverlusts: " + ((road.time_loss === null) ? 0 : road.time_loss.toFixed(4)) + " | Gut [0;30] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* V-Score-V1-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "V1Seg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((i) => {

                    const road = roadsData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', 'yellow', 'red']);

                    return (
                        <Polyline
                            positions={road.geometry.coordinates}
                            color={colormap((road.v_score_v1 + 1) * 0.3788)}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen: " + ((road.v_score_v1 === null) ? 0 : road.v_score_v1.toFixed(4)) + " | Gut [-1;1,64] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* V-Score-V2-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "V2Seg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((i) => {

                    const road = roadsData[i];

                    const interpolate = require('color-interpolate');
                    let colormap = interpolate(['green', 'yellow', 'red']);

                    return (
                        <Polyline
                            positions={road.geometry.coordinates}
                            color={colormap((road.v_score_v2 + 1) * 0.3788)}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen unter Berücksichtigung der Geschwindigkeit: " + ((road.v_score_v2 === null) ? 0 : road.v_score_v2.toFixed(4)) + " | Gut [-1;1,64] Schlecht"}
                            </Tooltip>
                        </Polyline>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default RoadsLayer;