import React from "react";
import {getCities, getTimeDimensions, getTopics} from "../../functions/Translations";
import dayjs from "dayjs";

const StepOverview = ({city, topic, road, timeDimension, start, end}) => {
    const cities = getCities();
    const topics = getTopics();
    const timeDimensions = getTimeDimensions();

    return (
        <div className="flex">
            <table className="api-form-block submit item adjust endcard" style={{width: '100%'}}>
                <tbody>
                <tr>
                    <td className="col" colSpan="2"><h2 className="auswahlTitel">Alles fertig?</h2></td>
                </tr>
                <tr>
                    <td className="col"><strong>Stadt:</strong></td>
                    <td>{cities[city]}</td>
                </tr>
                <tr>
                    <td className="col"><strong>Kennzahl:</strong></td>
                    <td>{topics[topic]}</td>
                </tr>
                <tr hidden={road === ""}>
                    <td className="col"><strong>Zählstelle:</strong></td>
                    <td>{road}</td>
                </tr>
                <tr>
                    <td className="col"><strong>Zeitdimension:</strong></td>
                    <td>{timeDimensions[timeDimension]}</td>
                </tr>
                <tr>
                    <td className="col"><strong>Zeitraum:</strong></td>
                    <td>{dayjs(start).format("DD.MM.YYYY") + " - " + dayjs(end).format("DD.MM.YYYY")}</td>
                </tr>
                <tr hidden={!(timeDimension === "hour" || timeDimension === "quarter")}>
                    <td className="col"><strong>Tageszeitraum:</strong></td>
                    <td>{dayjs(start).format("HH:mm") + " - " + dayjs(end).format("HH:mm") + " Uhr"}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default StepOverview;