import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {roadSegmentsConditionsOptions, roadSegmentsConditionsTranslations} from "../../functions/FilterFunctions";

function FilterRoadSegmentScore({roadSegmentState, updateRoadSegmentState, updateLoadingSpin}) {

    return (
        <div>
            {roadSegmentsConditionsOptions.map((roadType, index) => (
                <button key={index}
                        className={`col-12 filterBtn ${roadSegmentState === roadType ? "selected" : ""}`}
                        onClick={() => {updateRoadSegmentState(roadType); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container">
                                <img src={roadSegmentState === roadType ? checked : unchecked}
                                     alt={roadSegmentState === roadType ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {roadSegmentsConditionsTranslations[roadType]}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterRoadSegmentScore;