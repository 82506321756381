import {ApiConnect} from "../Dashboard";
import L from "leaflet";

export function getMapBounds(mapBounds) {
    const latMax = mapBounds.getNorthEast().lat;
    const lngMax = mapBounds.getNorthEast().lng;

    const latMin = mapBounds.getSouthWest().lat;
    const lngMin = mapBounds.getSouthWest().lng;

    return {latMax, lngMax, latMin, lngMin}
}

export function onlyRoadScoreCol(column, mapBounds, updateRoadScoreData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchOnlyRoadScoreCol(column, latMax, lngMax, latMin, lngMin)
        .then((scoreData) => {
            let scoreData_adjusted = {};

            if (scoreData !== null) {
                scoreData_adjusted = scoreData.map((element) => {
                    let coordinates = element.geometry.coordinates;
                    coordinates.forEach(function (value, i) {
                        coordinates[i] = [value[1], value[0]];
                    });
                    element.geometry.coordinates = coordinates;
                    return element;
                });
            }

            updateRoadScoreData(scoreData_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchNodesData(column, mapBounds, updateNodesData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchNodesData(column, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            let data_adjusted = {};

            if (data !== null) {
                data_adjusted = data.map((element) => {
                    const coordinates = element.geometry.coordinates[0].map(value => [value[1], value[0]]);
                    element.geometry.coordinates = [coordinates];
                    return element;
                });
            }

            updateNodesData(data_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchRoadsData(column, mapBounds, updateRoadsData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchRoadsData(column, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            let data_adjusted = {};

            if (data !== null) {
                data_adjusted = data.map(element => {
                    element.geometry.coordinates = element.geometry.coordinates.map(value => [value[1], value[0]]);
                    return element;
                });
            }

            updateRoadsData(data_adjusted);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchHeatmapData(column, mapBounds, updateData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchHeatmapData(column, latMax, lngMax, latMin, lngMin)
        .then((heatMapData) => {
            let heatMapData_adjusted = {};

            if (heatMapData !== null) {
                heatMapData_adjusted = heatMapData.map((element) => {
                    const coordinates = element.geometry.coordinates[0].map((value) => [value[1], value[0]]);
                    element.geometry.coordinates = [coordinates];
                    return element;
                });
            }

            updateData(heatMapData_adjusted);
            updateLoadingSpin(false);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function fetchBusLinesData(mapBounds, updateBusData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchBusLines(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const groupedBusData = {};

            if (data !== null) {
                data.forEach((element) => {
                    const busLineName = element.bus_line_name;
                    const busLineId = element.bus_line_id.substring(0, 5);
                    const coordinates = element.geometry.coordinates.map((coord) => [
                        coord[1],
                        coord[0],
                    ]);
                    if (groupedBusData[busLineName]) {
                        groupedBusData[busLineName].push({busLineId, coordinates});
                    } else {
                        groupedBusData[busLineName] = [{busLineId, coordinates}];
                    }
                });
            }

            updateBusData(groupedBusData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchStationsData(sD, eD, sDQuarter, eDQuarter, updateGroupedData, updateLoadingSpin) {
    Promise.all([
        ApiConnect.fetchStationsGeoData15min(), // API-Aufruf für 15-Minuten-Daten
        ApiConnect.fetchStationsGeoDataJahreswerte(),// API-Aufruf für Jahreswerte-Daten
        ApiConnect.fetchStationsGeoDataTimeRange(sD, eD, sDQuarter, eDQuarter)
    ])
        .then(([data15min, dataJahreswerte, dataRange]) => {
            const newData = {};

            // Daten zusammenführen (gruppieren) und in den Zustand speichern
            data15min.forEach(item => {
                const {station_id, ...rest} = item;
                newData[station_id] = {...(newData[station_id] || {}), ...rest};
            });

            dataJahreswerte.forEach(item => {
                const {station_id, ...rest} = item;
                newData[station_id] = {...(newData[station_id] || {}), ...rest};
            });

            dataRange.forEach(item => {
                const {station_id, sum_countto, sum_countfrom, max_bothdirections, sum_bothdirections, ...rest} = item;
                newData[station_id] = {
                    ...(newData[station_id] || {}),
                    sum_countto_range: sum_countto,
                    sum_countfrom_range: sum_countfrom,
                    max_bothdirections_range: max_bothdirections,
                    sum_bothdirections_range: sum_bothdirections,
                    ...rest
                };
            });
            updateGroupedData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchCarStationsData(town, mapRef, sD, eD, initialBounds, updateMapBounds,
                                     updateGroupedCarStations, updateGroupedCarStationsOl, updateLoadingSpin) {

    if (town === "Osnabrueck") {
        if (mapRef !== null) {

            const map = mapRef.current;
            if (map) {
                const bounds = map.getBounds();
                updateMapBounds(bounds);

                Promise.all([
                    ApiConnect.fetchCarStationsOsnaYearPeak(),
                    ApiConnect.fetchCarStationsOsnaYear(),
                    ApiConnect.fetchCarStationsOsnaMonth(),
                    ApiConnect.fetchCarStationsOsnaMaxAvg(),
                    ApiConnect.fetchCarStationsOsna(sD, eD)
                ])
                    .then(([dataCarStationsPeak, dataCarStationsYear, dataCarStationsMonth, dataCarStationsMaxAvg, dataCarStationsOsnaRange]) => {
                        const newData = {};

                        dataCarStationsPeak.forEach(item => {
                            const {station_id, ...rest} = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                ...rest,
                            };
                        });

                        dataCarStationsYear.forEach(item => {
                            const {station_id, sum_traffic_volume, avg_speed_per_hour, ...rest} = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                sum_traffic_volume_year: sum_traffic_volume,
                                avg_speed_per_hour_year: avg_speed_per_hour,
                                ...rest,
                            };
                        });
                        dataCarStationsMonth.forEach(item => {
                            const {
                                station_id,
                                sum_traffic_volume,
                                avg_speed_per_hour,
                                avg_vehicles_per_hour,
                                ...rest
                            } = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                sum_traffic_volume_month: sum_traffic_volume,
                                avg_speed_per_hour_month: avg_speed_per_hour,
                                avg_vehicles_per_hour_month: avg_vehicles_per_hour,
                                ...rest,
                            };
                        });
                        dataCarStationsMaxAvg.forEach(item => {
                            const {station_id, sum_traffic_volume, avg_speed_per_hour, ...rest} = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                sum_traffic_volume_custom: sum_traffic_volume,
                                avg_speed_per_hour_custom: avg_speed_per_hour,
                                ...rest,
                            };
                        });

                        dataCarStationsOsnaRange.forEach(item => {
                            const {
                                station_id,
                                sum_traffic_volume,
                                max_traffic_volume,
                                day_avg_traffic_volume,
                                hour_avg_traffic_volume,
                                ...rest
                            } = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                sum_traffic_volume_custom_range: sum_traffic_volume,
                                max_traffic_volume_range: max_traffic_volume,
                                day_avg_traffic_volume_range: day_avg_traffic_volume,
                                hour_avg_traffic_volume_range: hour_avg_traffic_volume,
                                ...rest,
                            };
                        });
                        updateGroupedCarStations(newData);
                        updateLoadingSpin(false);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }

    }

    if (town === "Oldenburg") {
        if (mapRef !== null) {

            const map = mapRef.current;
            if (map) {
                const bounds = map.getBounds();
                updateMapBounds(bounds);

                Promise.all([
                    ApiConnect.fetchCarStationsOldenburg(sD, eD, bounds.getNorthEast().lat, bounds.getNorthEast().lng, bounds.getSouthWest().lat, bounds.getSouthWest().lng),
                    ApiConnect.fetchCarStationsOldenburgGes(sD, eD, bounds.getNorthEast().lat, bounds.getNorthEast().lng, bounds.getSouthWest().lat, bounds.getSouthWest().lng)
                ])
                    .then(([dataOl, dataOlGesamt]) => {
                        const newData = {};

                        dataOl.forEach(item => {
                            const {
                                station_id,
                                sum_traffic_volume,
                                max_traffic_volume,
                                day_avg_traffic_volume,
                                hour_avg_traffic_volume,
                                ...rest
                            } = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                sum_traffic_volume_custom_ol: sum_traffic_volume,
                                max_traffic_volume_ol: max_traffic_volume,
                                day_avg_traffic_volume_ol: day_avg_traffic_volume,
                                hour_avg_traffic_volume_ol: hour_avg_traffic_volume,
                                ...rest,
                            };
                        });


                        dataOlGesamt.forEach(item => {
                            const {
                                station_id,
                                max_traffic_volume,
                                day_avg_traffic_volume,
                                hour_avg_traffic_volume,
                                ...rest
                            } = item;
                            newData[station_id] = {
                                ...(newData[station_id] || {}),
                                max_traffic_volume_ol_gesamt: max_traffic_volume,
                                day_avg_traffic_volume_ol_gesamt: day_avg_traffic_volume,
                                hour_avg_traffic_volume_ol_gesamt: hour_avg_traffic_volume,
                                ...rest,
                            };
                        });
                        console.log(newData);
                        updateGroupedCarStationsOl(newData);
                        updateLoadingSpin(false);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
        //initiale Schleife mit Startwerten--------------------------------------------------
        else {
            const bounds = initialBounds;

            Promise.all([
                ApiConnect.fetchCarStationsOldenburg(sD, eD, bounds.getNorthEast().lat, bounds.getNorthEast().lng, bounds.getSouthWest().lat, bounds.getSouthWest().lng),
                ApiConnect.fetchCarStationsOldenburgGes(sD, eD, bounds.getNorthEast().lat, bounds.getNorthEast().lng, bounds.getSouthWest().lat, bounds.getSouthWest().lng)
            ])
                .then(([dataOl, dataOlGesamt]) => {
                    const newData = {};

                    dataOl.forEach(item => {
                        const {
                            station_id,
                            sum_traffic_volume,
                            max_traffic_volume,
                            day_avg_traffic_volume,
                            hour_avg_traffic_volume,
                            ...rest
                        } = item;
                        newData[station_id] = {
                            ...(newData[station_id] || {}),
                            sum_traffic_volume_custom_ol: sum_traffic_volume,
                            max_traffic_volume_ol: max_traffic_volume,
                            day_avg_traffic_volume_ol: day_avg_traffic_volume,
                            hour_avg_traffic_volume_ol: hour_avg_traffic_volume,
                            ...rest,
                        };
                    });


                    dataOlGesamt.forEach(item => {
                        const {
                            station_id,
                            max_traffic_volume,
                            day_avg_traffic_volume,
                            hour_avg_traffic_volume,
                            ...rest
                        } = item;
                        newData[station_id] = {
                            ...(newData[station_id] || {}),
                            max_traffic_volume_ol_gesamt: max_traffic_volume,
                            day_avg_traffic_volume_ol_gesamt: day_avg_traffic_volume,
                            hour_avg_traffic_volume_ol_gesamt: hour_avg_traffic_volume,
                            ...rest,
                        };
                    });

                    updateGroupedCarStationsOl(newData);
                    updateLoadingSpin(false);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
}

export function fetchAccidents(sD, eD, sDQuarter, eDQuarter, mapBounds, updateGroupedAccData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchAccidentsGeoData(sD, eD, sDQuarter, eDQuarter, latMax, lngMax, latMin, lngMin)
        .then((dataAccidents) => {
            const newData = {};

            if (dataAccidents !== null) {
                let primaryKey = 1;

                dataAccidents.forEach(item => {
                    const datetime = new Date(item.datetime);
                    const year = datetime.getFullYear();
                    const month = datetime.getMonth() + 1;
                    const day = datetime.getDate();
                    const hours = datetime.getHours();
                    const minutes = datetime.getMinutes();

                    const formattedDateTime = `${datetime.getDate()}-${datetime.getMonth() + 1}-${datetime.getFullYear()} ${datetime.getHours()}:${datetime.getMinutes()}`;

                    if (!newData[year]) {
                        newData[year] = {};
                    }
                    if (!newData[year][month]) {
                        newData[year][month] = {};
                    }

                    newData[year][month][primaryKey] = {...item, formattedDateTime, year, month, day, hours, minutes};

                    primaryKey++;
                });
            }

            console.log(newData)
            updateGroupedAccData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchReportPlatformData(startDate, endDate, mapBounds, updateReportedImg, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchImageGeoDataForDashboard(startDate, endDate, latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {annotation_id, ...rest} = item;
                    newData[annotation_id] = {...(newData[annotation_id] || {}), ...rest};
                });
            }

            console.log(newData)
            updateReportedImg(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}


export function fetchConcerns(sD, eD, mapBounds, updateGroupedConcernsData, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchConcernsGeoData(sD, eD, latMax, lngMax, latMin, lngMin)
        .then((dataConcerns) => {
            const newData = {};

            if (dataConcerns !== null) {
                let primaryKey = 1;

                dataConcerns.forEach(item => {
                    const datetime = new Date(item.datetime);
                    const year = datetime.getFullYear();
                    const month = datetime.getMonth() + 1;
                    const day = datetime.getDate();

                    const formattedDateTime = `${datetime.getDate()}-${datetime.getMonth() + 1}-${datetime.getFullYear()}`;

                    if (!newData[year]) {
                        newData[year] = {};
                    }
                    if (!newData[year][month]) {
                        newData[year][month] = {};
                    }

                    newData[year][month][primaryKey] = {...item, formattedDateTime, year, month, day};

                    primaryKey++;
                });
            }

            updateGroupedConcernsData(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchBusStopsData(mapBounds, updateBusStops, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchBusStops(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {bus_stop_id, ...rest} = item;
                    newData[bus_stop_id] = {...(newData[bus_stop_id] || {}), ...rest};
                });
            }

            updateBusStops(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
            console.error(error);
        });
}

export function fetchRoadGeometry(mapBounds, updateRoadGeometry, updateLoadingSpin) {
    const {latMax, lngMax, latMin, lngMin} = getMapBounds(mapBounds);

    ApiConnect.fetchGeometryRoad(latMax, lngMax, latMin, lngMin)
        .then((data) => {
            const newData = {};

            if (data !== null) {
                data.forEach(item => {
                    const {geometry_id, slope, path_type, geometry, ...rest} = item;

                    if (slope !== null || path_type !== null) {

                        let coordinates = geometry.coordinates;
                        coordinates.forEach(function (value, i) {
                            coordinates[i] = [value[1], value[0]];
                        });
                        geometry.coordinates = coordinates;

                        newData[geometry_id] = {...(newData[geometry_id] || {}), slope, path_type, geometry, ...rest}
                    }
                });
            }

            updateRoadGeometry(newData);
            updateLoadingSpin(false);
        })
        .catch(error => {
        console.error(error);
        });
}