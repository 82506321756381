import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {sortByKey} from "../functions/CommonFunctions";

function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

function Line_Chart({data, dataX, dataY, colors, isOpen}) {
  data = Array.from(data);
  data = sortByKey(data, dataX);
  let ticks = null;
  let interval = null;

  if (dataX === "dow") {
    data.forEach(date => { if (date.dow === 0) { date.dow = 7; } });

    data.forEach(date => { if (date.dow === 1) { date.dow = "MO"; date.dateNumber = 1; } });
    data.forEach(date => { if (date.dow === 2) { date.dow = "DI"; date.dateNumber = 2; } });
    data.forEach(date => { if (date.dow === 3) { date.dow = "MI"; date.dateNumber = 3; } });
    data.forEach(date => { if (date.dow === 4) { date.dow = "DO"; date.dateNumber = 4; } });
    data.forEach(date => { if (date.dow === 5) { date.dow = "FR"; date.dateNumber = 5; } });
    data.forEach(date => { if (date.dow === 6) { date.dow = "SA"; date.dateNumber = 6; } });
    data.forEach(date => { if (date.dow === 7) { date.dow = "SO"; date.dateNumber = 7; } });
    data = sortByKey(data, "dateNumber");
  }
  else if (dataX === "quarter") {
    data.forEach(date => { if (date.quarter === "00:00:00") { date.quarter = "24:00:00"; } });
    data.forEach(date => date.quarter = date.quarter.substring(0, 5));
    data = sortByKey(data, dataX);
  }
  else if (dataX === "hour") {
    data.forEach(date => { if (date.hour === 0) { date.hour = 24; } }) // 0 to 24
    data = sortByKey(data, dataX);

    ticks = range(2, 24);
    interval = 1;
    if (isOpen) {
      ticks = range(1, 24);
      interval = 0;
    }    
  }
  else if (dataX === "day") {
    ticks = range(2, 30);
    interval = 1;
    if (isOpen) {
      ticks = range(1, 31);
      interval = 0;
    }    
  }

  return (
    <ResponsiveContainer width='99%' height='88%'>
      <LineChart width={800} height={300} data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey={dataX} ticks={ticks} interval={interval}/>
        <YAxis/>
        <Tooltip/>
        <Legend/>
        {dataY.map((dataKey, index) => (
          <Line type="monotone" dataKey={dataKey} stroke={colors[index]}/>
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default Line_Chart;