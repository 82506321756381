import React from 'react';
import logo_text_bottom from "../images/logo_text_bottom.png";

const NotExisting404 = () => (
      <div className="errorpage"> 
        <img className="logo_text_bottom errorimg" width="8%" src={logo_text_bottom} alt="logo" />
        <br />
        <h2>Es scheint, als wenn diese Seite einen Platten hat oder nicht existiert. Bitte wenden!</h2>
      </div>
);

export default NotExisting404;
